import {
  Badge,
  Box,
  Flex,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Td,
  Tr,
  UnorderedList,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import TableCustom from '../../../../components/pages/CandidateArea/Table';
import { RiSignalWifiErrorLine } from 'react-icons/ri';
import { useRequestMyExemption } from '../../../../services/hooks/CandidateArea/useRequestMyExemption';
import { TextM, TextXS, TextXXS } from '../../../../components/Text/Index';
import { badgeColor } from '../../../../utils/badgeFunction';
import { useState } from 'react';
import { ILocation } from '../../../../contexts/types';
import { ISelectObject } from '../../../../services/hooks/SelectiveProcess/useRequestSelectiveProcessVacancy';
import MessageForUser from '../../../../components/pages/SelectiveProcess/MessageForUser';

export interface IExemption {
  id: string;
  registration_code: number;
  selective_process_id: string;
  selective_process_name: string;
  status: string;
  type: string;
  detail: string;
  attachments: Attachment[];
  exemption_confirmation: string;
}

export interface IExemptionInterface {
  id: string;
  type: ISelectObject;
  justification: string;
  justification_date: string;
  status: string;
  nis: string;
  mother_name: string;
  document_number: string;
  document_issuing_body: string;
  document_issuing_body_uf: ILocation;
}

export interface Attachment {
  id: string;
  name: string;
  type: string;
  status: string;
  file: string;
  sent_by_client: boolean;
}

const Exemptions: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const headerExemption = ['Nº DA SOLICITAÇÃO', 'PROCESSOS SELETIVOS', 'SITUAÇÃO', 'TIPO DE SOLICITAÇÃO', 'AÇÕES'];
  const { data, isLoading, error } = useRequestMyExemption();
  const [selectedExemption, setSelectedExemption] = useState<IExemption>();
  const [isMobile] = useMediaQuery('(max-width: 1024px)');

  // const handleDelete = async (ExemptionId: string, selectiveProcessId: string) => {
  //   const response = await configApi.delete(
  //     `exemption/candidate/delete/${ExemptionId}/?selective_process_id=${selectiveProcessId}`,
  //   );
  //   return response.data;
  // };

  const handleDetail = (reserved: IExemption) => {
    setSelectedExemption(reserved);
    onOpen();
  };

  return (
    <>
      <Stack height="100%">
        {(isLoading && (
          <Stack height="100%" justify="center" align="center">
            <Spinner size="lg" />
          </Stack>
        )) ||
          (error && (
            <Stack height="100%" color="red" justify="center" align="center">
              <RiSignalWifiErrorLine size={50} />
              <TextXS fontWeight="bold" fontSize="16px">
                Erro ao trazer os dados!
              </TextXS>
            </Stack>
          )) || (
            <>
              {isMobile ? (
                <Stack px={['10px', '10px', '10px', '50px', '50px']}>
                  {data &&
                    data.map((exemption) => (
                      <Stack
                        border="1px solid"
                        borderTopLeftRadius={4}
                        borderTopRightRadius={4}
                        w={'100%'}
                        bg="primaryColor"
                      >
                        <TextXS
                          color="white"
                          fontWeight="bold"
                          fontSize={13}
                          p={2}
                          px={4}
                          borderTopLeftRadius={3}
                          borderTopRightRadius={3}
                        >
                          {exemption?.selective_process_name}
                        </TextXS>
                        <Stack bg="white" key={exemption?.selective_process_id} p={5}>
                          <Flex gap={1} justify="center" flexDir={'column'}>
                            <TextXS fontWeight="bold">
                              Nº DA SOLICITAÇÃO:{' '}
                              <TextXS fontWeight="normal" as={'span'}>
                                {exemption?.registration_code}
                              </TextXS>
                            </TextXS>
                          </Flex>

                          <Flex gap={1} justify="center" flexDir={'column'}>
                            <TextXS fontWeight="bold">
                              Tipo da solicitação:{' '}
                              <TextXS fontWeight="normal" as={'span'}>
                                {exemption?.type}
                              </TextXS>
                            </TextXS>
                          </Flex>

                          <Flex justify="center" flexDir={'column'} gap={1}>
                            <TextXS fontWeight="bold">
                              Situação:{' '}
                              <Badge
                                borderRadius={4}
                                textAlign="center"
                                lineHeight="normal"
                                p={1}
                                px={2}
                                bg={badgeColor(exemption?.status)?.bgColor || 'black'}
                                color={badgeColor(exemption?.status)?.color || 'white'}
                              >
                                {exemption?.status}
                              </Badge>
                            </TextXS>
                          </Flex>

                          <Flex gap={1}>
                            <TextXS
                              onClick={() => handleDetail(exemption)}
                              cursor="pointer"
                              color="colorLink"
                              textAlign="center"
                              fontWeight="bold"
                            >
                              Detalhes
                            </TextXS>
                          </Flex>
                        </Stack>
                      </Stack>
                    ))}
                </Stack>
              ) : (
                <Stack px={['10px', '10px', '10px', '50px', '50px']}>
                  {data ? (
                    <TableCustom headers={headerExemption}>
                      {data &&
                        data?.map((exemption) => (
                          <Tr key={exemption.id}>
                            <Td textAlign="center">
                              <TextXS onClick={() => handleDetail(exemption)} color="colorLink" cursor="pointer">
                                {exemption.registration_code}
                              </TextXS>
                            </Td>
                            <Td textAlign="center">
                              <TextXS>{exemption.selective_process_name}</TextXS>
                            </Td>
                            <Td textAlign="center">
                              <Badge
                                borderRadius={4}
                                textAlign="center"
                                lineHeight="normal"
                                p={1}
                                px={2}
                                bg={badgeColor(exemption?.status)?.bgColor || 'black'}
                                color={badgeColor(exemption?.status)?.color || 'white'}
                              >
                                {exemption?.status}
                              </Badge>
                            </Td>
                            <Td textAlign="center">{exemption.type}</Td>
                            <Td textAlign="center">
                              <TextXS onClick={() => handleDetail(exemption)} color="colorLink" cursor="pointer" ml={2}>
                                Detalhes
                              </TextXS>
                            </Td>
                          </Tr>
                        ))}
                    </TableCustom>
                  ) : (
                    <Stack h="100%" justify="center" align="center">
                      <TextM>Sem pedidos de isenção registrados</TextM>
                    </Stack>
                  )}
                </Stack>
              )}
            </>
          )}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent pb={10} maxW={['80%', '80%', '80%', '40%', '40%']} maxH="none">
          <ModalHeader textAlign="center">Detalhes do pedido de Isenção</ModalHeader>
          <ModalCloseButton />

          {selectedExemption && (
            <ModalBody display="flex" flexDir="column" gap={1}>
              {selectedExemption?.exemption_confirmation && (
                <MessageForUser mb={3} informationText={selectedExemption?.exemption_confirmation} />
              )}

              <TextXS>
                <strong>Processo seletivo: </strong> {selectedExemption?.selective_process_name}
              </TextXS>

              <TextXS>
                <strong>Nº da solicitação: </strong> {selectedExemption?.registration_code}
              </TextXS>

              <TextXS>
                <strong>Tipo: </strong> {selectedExemption?.type}
              </TextXS>

              <TextXS>
                <strong>Situação do pedido: </strong> {selectedExemption?.status}
              </TextXS>

              {selectedExemption?.detail && (
                <Flex gap={1}>
                  <TextXS>
                    <strong>Resposta da solicitação: </strong>
                  </TextXS>
                  <TextXS dangerouslySetInnerHTML={{ __html: selectedExemption?.detail }} />
                </Flex>
              )}

              {selectedExemption.attachments.length > 0 && (
                <Box>
                  <TextXS fontWeight="bold">Anexos enviados: </TextXS>
                  <UnorderedList ml={'25px'} styleType={''}>
                    {selectedExemption.attachments.map((attach, index) => (
                      <ListItem key={index}>
                        <Flex gap={1}>
                          <Link color="colorLink" href={attach?.file} target="_blank">
                            <TextXXS>{attach?.name}</TextXXS>
                          </Link>
                          <TextXXS color="#4e4e4e">
                            {attach?.sent_by_client ? '(Enviado pelo Administrador)' : '(Enviado pelo candidato)'}
                          </TextXXS>
                        </Flex>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              )}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Exemptions;
