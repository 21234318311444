import { Box, Flex, FlexProps, Image } from '@chakra-ui/react';
import bgImage from '../../assets/folha-de-pontuacao-de-teste-com-respostas_93675-5226-transformed (1).jpeg';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetClientData } from '../../services/hooks/ClientData/useGetClientData';

interface IOwnProps {
  children: ReactNode;
}

type props = IOwnProps & FlexProps;

const DefaultPage: React.FC<props> = ({ children, ...rest }) => {
  const navigation = useNavigate();
  const ClientData = useGetClientData();

  return (
    <Flex height="100%" w="100%" alignItems="center" bg="lightGrey">
      <Box display={['none', 'none', 'none', 'block', 'block']} h="100%" w={['0', '0', '0', '67%', '75%']}>
        <Image objectFit="cover" w="full" h="full" src={bgImage} />
      </Box>
      <Flex
        bg="lighgrey"
        display="flex"
        height="100%"
        w={['100%', '100%', '100%', '33%', '25%']}
        px={['10%', '10%', '25%', '2%', '2%']}
        boxShadow="0px 0px 15px black"
      >
        <Flex
          w="100%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          gap={['8%', '8%', '8%', '8%', '6%']}
          {...rest}
        >
          <Image w="75%" cursor="pointer" onClick={() => navigation('/')} src={ClientData?.data?.main_logo} />
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DefaultPage;
