import { Button, Divider, Flex, Spinner, Stack } from '@chakra-ui/react';
import MainCard from '../../../components/MainCard/Index';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import * as Yup from 'yup';

// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import NIS from '../Exemptions/Forms/NIS/Index';
// import MarrowDonnor from '../Exemptions/Forms/MarrowDonnor/Index';
// import { useRequestGetExemptionType } from '../../../services/hooks/Exemptions/useRequestGetExemptionType';
import { FaCircleCheck } from 'react-icons/fa6';
import { useGetRegistration } from '../../../services/hooks/Registration/useGetRegistration';
import InvoicePDFButton from '../../../components/PDFs/DownloadPDFButton/invoiceButton';
import { useRef, useState } from 'react';
import { IoMdPrint } from 'react-icons/io';
import ProofRegistrationButton from '../../../components/PDFs/DownloadPDFButton/proofRegistrationButton';
import { TextM, TextS, TextXS } from '../../../components/Text/Index';

interface IOwnProps {
  previousRoute?: string;
  registrationId?: string;
}

const SummaryRegistration: React.FC<IOwnProps> = () => {
  const [isLoadFilePrint, setIsLoadFilePrint] = useState<boolean>(true);
  const [generatedPrint, setGeneratedPrint] = useState<boolean>(false);
  const navigate = useNavigate();
  // const registrationId = useLocation().state as string;
  // const getExemption = useRequestGetExemptionType();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const navProps = useLocation().state as IOwnProps;
  const [params] = useSearchParams();
  const standbyExemption = params.get('standbyExemption');
  const registrationId = useLocation().pathname.split('/')[4];
  const getRegistration = useGetRegistration(registrationId || '');

  const is_exemption = getRegistration?.data?.exemptions || [];

  const handleImprimir = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      if (!generatedPrint) {
        const spn = iframe.contentWindow?.document.getElementById('selective-process-name');
        const rn = iframe.contentWindow?.document.getElementById('registration-number');
        const rd = iframe.contentWindow?.document.getElementById('registration-date');
        const name = iframe.contentWindow?.document.getElementById('name');
        const cpf = iframe.contentWindow?.document.getElementById('cpf');
        const idoc = iframe.contentWindow?.document.getElementById('idoc');
        const gender = iframe.contentWindow?.document.getElementById('gender');
        const dob = iframe.contentWindow?.document.getElementById('dob');
        const address = iframe.contentWindow?.document.getElementById('address');
        const exemption = iframe.contentWindow?.document.getElementById('exemption');
        const status = iframe.contentWindow?.document.getElementById('rs');
        const contact = iframe.contentWindow?.document.getElementById('contacts');
        const payment_status = iframe.contentWindow?.document.getElementById('payment_status');
        const pcd = iframe.contentWindow?.document.getElementById('pcd');
        const special_condition = iframe.contentWindow?.document.getElementById('special_condition');
        const type_of_competition = iframe.contentWindow?.document.getElementById('type_of_competition');
        const spv1 = iframe.contentWindow?.document.getElementById('selective-process-vacancy');
        const spvLabel1 = iframe.contentWindow?.document.getElementById('spvLabel1');
        const spvLabel2 = iframe.contentWindow?.document.getElementById('spvLabel2');
        const spv2 = iframe.contentWindow?.document.getElementById('selective-process-second-vacancy');
        const title = iframe.contentWindow?.document.getElementById('title');

        if (title) {
          title.innerText += `${standbyExemption ? 'COMPROVANTE DE INSCRIÇÃO' : getRegistration.data?.exemptions && getRegistration.data?.exemptions?.length > 0 ? 'COMPROVANTE DE ISENÇÃO' : 'COMPROVANTE DE INSCRIÇÃO'}`;
        }

        if (spn) {
          spn.innerText += ` ${getRegistration.data?.selective_process_vacancy?.selective_process?.name || ''}`;
        }

        if (spv1) {
          spv1.innerText += ` ${getRegistration.data?.selective_process_vacancy?.name || ''}`;
        }

        if (spvLabel1) {
          if (
            getRegistration.data?.selective_process_vacancy?.cultural_house &&
            getRegistration.data?.selective_process_vacancy.class_level
          ) {
            spvLabel1.innerText = `Turma:  `;
          } else if (
            getRegistration.data?.selective_process_vacancy?.cultural_house &&
            !getRegistration.data?.selective_process_vacancy.class_level
          ) {
            spvLabel1.innerText = `Turma - primeira opção: `;
          } else {
            spvLabel1.innerText = `Vaga: `;
          }
        }

        if (spvLabel2) {
          if (getRegistration.data?.second_vacancy) {
            spvLabel2.innerText = `Turma - segunda opção: `;
          } else {
            spvLabel2.innerText = ``;
          }
        }

        if (spv2) {
          spv2.innerText += `${getRegistration.data?.second_vacancy?.name || ''}`;
        }

        if (rn) {
          rn.innerText += ` ${getRegistration.data?.registration_code || ''}`;
        }

        if (rd) {
          rd.innerText += ` ${getRegistration.data?.created_at.br || ''}`;
        }

        if (name) {
          name.innerText += ` ${getRegistration.data?.candidate?.user?.full_name || ''}`;
        }

        if (cpf) {
          cpf.innerText += ` ${getRegistration.data?.candidate?.cpf || ''}`;
        }

        if (idoc) {
          idoc.innerText += `${getRegistration.data?.candidate?.document_type?.name || ''}: ${getRegistration.data?.candidate?.document_number || ''} - ${getRegistration.data?.candidate?.document_issuing_body || ''} - ${getRegistration.data?.candidate?.document_issuing_body_uf.initials || ''} - expedido: ${getRegistration.data?.candidate?.document_date_of_issuance.br || ''}`;
        }

        if (gender) {
          gender.innerText += ` ${getRegistration.data?.candidate?.gender?.name || ''}`;
        }

        if (dob) {
          dob.innerText += ` ${getRegistration.data?.candidate?.date_of_birth.br || ''}`;
        }

        if (address) {
          address.innerText += ` ${getRegistration.data?.candidate.address_street || '--'}, N° ${getRegistration.data?.candidate.address_number || '--'} - ${getRegistration.data?.candidate?.address_complement || ''}, ${getRegistration.data?.candidate.address_neighborhood || '--'} - CEP: ${getRegistration.data?.candidate.address_zip_code || '--'} - ${getRegistration.data?.candidate.address_city?.name || '--'}/${getRegistration.data?.candidate.address_state?.initials || '--'}`;
        }

        if (exemption) {
          exemption.innerText += ` ${getRegistration.data?.is_exemption ? 'Sim' : 'Não'}`;
        }

        if (status) {
          status.innerText += ` ${getRegistration.data?.registration_status?.name || ''}`;
        }

        if (type_of_competition) {
          getRegistration?.data?.type_of_competition?.forEach((type) => {
            const paragraph = iframe?.contentWindow?.document?.createElement('p');
            if (paragraph) {
              paragraph.style.fontSize = '12px';
              paragraph.innerText += `${type.name}: SIM `;
              type_of_competition?.appendChild(paragraph);
            }
          });
        }

        if (getRegistration.data?.candidate?.user.email) {
          const createText = iframe.contentWindow?.document.createElement('p');
          if (createText) {
            createText.style.marginLeft = '40px';
            createText.style.fontSize = '12px';
            createText.innerText += `- E-Mail: ${getRegistration.data?.candidate?.user?.email}`;
            contact?.appendChild(createText);
          }
        }

        if (getRegistration.data?.candidate?.phone) {
          const createText = iframe.contentWindow?.document.createElement('p');
          if (createText) {
            createText.style.marginLeft = '40px';
            createText.style.fontSize = '12px';
            createText.innerText += `- Telefone: ${getRegistration.data.candidate.phone}`;
            contact?.appendChild(createText);
          }
        }

        if (getRegistration.data?.candidate?.cellphone) {
          const createText = iframe.contentWindow?.document.createElement('p');
          if (createText) {
            createText.style.marginLeft = '40px';
            createText.style.fontSize = '12px';
            createText.innerText += `- Celular: ${getRegistration.data.candidate.cellphone}`;
            contact?.appendChild(createText);
          }
        }

        if (payment_status && getRegistration.data?.invoice?.status_invoice) {
          const labelInvoice = iframe?.contentWindow?.document?.createElement('p');
          const labelStatusInvoice = iframe?.contentWindow?.document?.createElement('p');

          if (labelInvoice && labelStatusInvoice) {
            labelInvoice.style.fontWeight = 'bold';
            labelInvoice.style.paddingTop = '0px';
            labelInvoice.style.paddingBottom = '0px';
            labelInvoice.style.paddingLeft = '5px';
            labelInvoice.style.fontSize = '13px';
            labelInvoice.innerText = 'SITUAÇÃO DE PAGAMENTO: ';
            labelStatusInvoice.innerText = getRegistration?.data?.invoice?.status_invoice || '';
            labelStatusInvoice.style.fontSize = '13px';

            labelStatusInvoice.style.marginLeft = '1px';
            payment_status.appendChild(labelInvoice);
            payment_status.appendChild(labelStatusInvoice);
          }
        }

        const attachmentPCD = getRegistration?.data?.reserved_vacancy.filter((attach) =>
          attach.type.toLowerCase().includes('pcd'),
        );
        if (pcd && attachmentPCD && attachmentPCD.length > 0) {
          pcd.style.display = 'flex';
          pcd.style.flexDirection = 'column';
          const createText = iframe?.contentWindow?.document?.createElement('p');
          if (createText) {
            createText.style.fontSize = '12px';
            createText.innerText = 'Anexo de documentos PCD:';
            pcd.appendChild(createText);
          }

          attachmentPCD[0]?.attachments?.forEach((item) => {
            const createSubText = iframe?.contentWindow?.document?.createElement('p');
            if (createSubText) {
              createSubText.style.fontSize = '12px';
              createSubText.style.display = 'block';
              createSubText.style.marginLeft = '40px';
              createSubText.innerText = `- ${item.name} - ${item.created_at.br} (enviado pelo ${item.sent_by_client ? 'administrador' : 'candidato'})`;
              pcd.appendChild(createSubText);
            }
          });
        }

        const attachmentSpecialCondition = getRegistration.data?.special_conditions;
        if (special_condition && attachmentSpecialCondition && attachmentSpecialCondition.length > 0) {
          special_condition.style.display = 'flex';
          special_condition.style.flexDirection = 'column';
          const createText = iframe?.contentWindow?.document?.createElement('p');
          if (createText) {
            createText.style.fontSize = '12px';
            createText.innerText = 'Anexo de Condições Especiais:';
            special_condition.appendChild(createText);
          }

          attachmentSpecialCondition.forEach((item) => {
            const createSubText = iframe?.contentWindow?.document?.createElement('p');
            if (createSubText) {
              createSubText.style.fontSize = '12px';
              createSubText.style.display = 'block';
              createSubText.style.marginLeft = '40px';
              createSubText.innerText = item.name;
              special_condition.appendChild(createSubText);

              if (item.attachments && item.attachments.length > 0) {
                item.attachments.forEach((file) => {
                  const createSubSubText = iframe?.contentWindow?.document?.createElement('p');
                  if (createSubSubText) {
                    createSubSubText.style.fontSize = '12px';
                    createSubSubText.style.display = 'block';
                    createSubSubText.style.marginLeft = '60px';
                    createSubSubText.innerText = `- ${file.name} - ${file.created_at.br} (enviado pelo ${file.sent_by_client ? 'candidato' : 'administrador'})`;
                    special_condition.appendChild(createSubSubText);
                  }
                });
              }
            }
          });
        }
        setGeneratedPrint(true);
      }

      iframe.contentWindow?.print();
    }
  };

  // const handleShowForm = (): ReactNode => {
  //   const form = watch('type_exemption');

  //   if (form) {
  //     const formToShow = JSON.parse(form);

  //     if (formToShow?.name?.toLowerCase().includes('doador')) {
  //       return <MarrowDonnor exemptionId={formToShow.id} registrationId={navProps?.registrationId || ''} />;
  //     } else {
  //       return (
  //         <NIS onSetValue={setValue} exemptionId={formToShow.id} registrationId={navProps?.registrationId || ''} />
  //       );
  //     }
  //   }

  //   return false;
  // };

  return (
    <MainCard
      title={
        standbyExemption
          ? 'RESUMO DA INSCRIÇÃO'
          : is_exemption?.length > 0
            ? 'RESUMO DO PEDIDO DE ISENÇÃO'
            : 'RESUMO DA INSCRIÇÃO'
      }
    >
      <Flex flexDir="column" px={['10px', '10px', '10px', '50px', '50px']}>
        {navProps?.previousRoute === '/painel' ? (
          <></>
        ) : (
          <Flex align="center" gap={3}>
            <FaCircleCheck size={30} fill="green" />
            {standbyExemption ? (
              <TextM as="span">
                Parabéns! Sua inscrição foi realizada com sucesso e seu número de inscrição é{' '}
                <strong>{getRegistration.data?.registration_code || '--'}</strong>.
              </TextM>
            ) : is_exemption?.length > 0 ? (
              <TextM as="span">Parabéns! Seu pedido de isenção foi realizada com sucesso.</TextM>
            ) : (
              <TextM as="span">
                Parabéns! Sua inscrição foi realizada com sucesso e seu número de inscrição é{' '}
                <strong>{getRegistration.data?.registration_code || '--'}</strong>.
              </TextM>
            )}
          </Flex>
        )}

        <Flex
          as="iframe"
          title="Imprimir Inscrição"
          src={`${document.location.origin}/imprimir-registration`}
          ref={iframeRef}
          display="none"
          onLoad={() => setIsLoadFilePrint(false)}
        />
        <Stack py={10}>
          {standbyExemption ? (
            <TextM fontWeight="bold">RESUMO DA INSCRIÇÃO:</TextM>
          ) : is_exemption.length > 0 ? (
            <TextM fontWeight="bold">RESUMO DO PEDIDO DE ISENÇÃO:</TextM>
          ) : (
            <TextM fontWeight="bold">RESUMO DA INSCRIÇÃO:</TextM>
          )}

          <TextXS>
            <strong>Situação: </strong>
            {getRegistration.data?.registration_status?.name}
          </TextXS>

          <TextXS>
            <strong>Processo seletivo: </strong>
            {getRegistration.data?.selective_process_vacancy?.selective_process?.name || '--'}
          </TextXS>
          <TextXS>
            <strong>Número de Inscrição:</strong> {getRegistration.data?.registration_code || '--'}
          </TextXS>

          <TextXS>
            <strong>Nome completo: </strong>
            {getRegistration.data?.candidate?.user?.full_name || '--'}
          </TextXS>

          {getRegistration.data?.candidate?.social_name && (
            <TextXS>
              <strong>Nome social: </strong>
              {getRegistration.data?.candidate?.social_name || '--'}
            </TextXS>
          )}

          <TextXS>
            <strong>CPF: </strong>
            {getRegistration.data?.candidate?.cpf || '--'}
          </TextXS>
          <TextXS>
            <strong>
              {getRegistration.data?.selective_process_vacancy?.cultural_house
                ? getRegistration.data?.selective_process_vacancy?.class_level
                  ? 'Turma: '
                  : 'Turma - Primeira opção: '
                : 'Vaga: '}
            </strong>
            {getRegistration.data?.selective_process_vacancy?.name || '--'}
            <TextXS>{getRegistration.data?.selective_process_vacancy?.details || ''}</TextXS>
          </TextXS>
          {getRegistration.data?.second_vacancy?.name && (
            <TextXS>
              <strong>Turma - Segunda opção: </strong>
              {getRegistration.data?.second_vacancy?.name || '--'}
              <TextXS>{getRegistration.data?.second_vacancy?.details || ''}</TextXS>
            </TextXS>
          )}

          {getRegistration.data?.invoice?.status_invoice && (
            <TextXS>
              Situação do boleto: <strong>{getRegistration.data?.invoice?.status_invoice}</strong>
            </TextXS>
          )}

          <Stack mt={4} w={['100%', '100%', 'fit-content', 'fit-content', 'fit-content']}>
            {getRegistration?.data?.show_print_registration_button && (
              <Button
                p={2}
                fontSize="12px"
                color="white"
                bg="primaryColor"
                whiteSpace="break-spaces"
                h="fit-content"
                onClick={handleImprimir}
                leftIcon={<IoMdPrint size={23} />}
                justifyContent="left"
              >
                {(isLoadFilePrint && <Spinner />) || standbyExemption
                  ? 'IMPRIMIR COMPROVANTE DE INSCRIÇÃO'
                  : getRegistration?.data?.exemptions && getRegistration?.data?.exemptions?.length > 0
                    ? 'IMPRIMIR COMPROVANTE DE ISENÇÃO'
                    : 'IMPRIMIR COMPROVANTE DE INSCRIÇÃO'}
              </Button>
            )}

            {getRegistration?.data?.show_download_registration_button && (
              <ProofRegistrationButton data={getRegistration.data} />
            )}

            {getRegistration?.data?.show_view_registration_button && (
              <ProofRegistrationButton data={getRegistration.data} isModeViewer />
            )}
          </Stack>
        </Stack>

        {/* {getRegistration.data?.social_name && (
          <>
            <Divider border="1px solid darkGrey" />

            <Stack py={10}>
              <TextM fontWeight="bold">SOLICITAR ATENDIMENTO PELO NOME SOCIAL</TextM>
              <TextXS>
                Candidato que optarem por atendimento pelo nome social devem preencher a solicitação no link abaixo:
              </TextXS>
              <Button
                h="fit-content"
                onClick={() => {
                  navigate('/processo-seletivo/inscricao/nome-social', {
                    state: { registrationId: navProps.registrationId, previousRoute: navProps.previousRoute },
                  });
                }}
                p={2}
                fontSize="14px"
                mt={3}
                color="white"
                bg="primaryColor"
                w={['100%', '100%', 'fit-content', 'fit-content', 'fit-content']}
                whiteSpace="break-spaces"
              >
                ENVIAR SOLICITAÇÃO DE ATENDIMENTO PELO NOME SOCIAL
              </Button>
            </Stack>
          </>
        )} */}

        <Divider border="1px solid darkGrey" />

        {!getRegistration?.data?.registration_status?.name.toLowerCase().includes('aguardando') ? (
          <></>
        ) : (
          <>
            <Stack py={10}>
              <TextS>
                <strong>Atenção!</strong> Sua inscrição somente será efetivada após a confirmação do pagamento da taxa
                de inscrição e sua compensação bancária.
              </TextS>
              <TextS>
                <strong>Valor da inscrição:</strong> R$ {getRegistration.data?.selective_process_vacancy?.value || '--'}
              </TextS>

              <InvoicePDFButton registrationId={registrationId || ''} />
            </Stack>

            <Divider border="1px solid darkGrey" />
          </>
        )}

        {/* {getRegistration?.data?.can_open_another_exemption_request && (
          <Stack py={10}>
            <TextM fontWeight="bold">SOLICITAR ISENÇÃO DA TAXA DE INSCRIÇÃO</TextM>

            <FormControl>
              <FormLabel>Tipo de solicitação de Isenção</FormLabel>
              <Select {...register('type_exemption')} w="100%" maxW="350px">
                <option hidden value="">
                  Selecione
                </option>
                {getExemption?.data?.map((exemption, index) => (
                  <option key={index} value={JSON.stringify(exemption)}>
                    {exemption?.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            {handleShowForm()}
          </Stack>
        )} */}

        {/* <Divider border="1px solid darkGrey" /> */}

        <Stack my={10} alignItems="flex-end">
          <Button
            color="white"
            bg="colorLink"
            w="150px"
            onClick={() => {
              navigate('/painel');
            }}
            _hover={{
              bg: '#5389ca',
            }}
          >
            Voltar
          </Button>
        </Stack>
      </Flex>
    </MainCard>
  );
};

export default SummaryRegistration;
