import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface IClientData {
  id: string;
  name: string;
  cnpj: string;
  company_name: string;
  report_logo_1: string;
  report_logo_2: string;
  white_logo: string;
  short_logo: string;
  portrait_logo: string;
  main_logo: string;
  institute_logo: string;
  client_logo: string;
  title: string;
  sub_title: string;
  suport_email: string;
  primary_color: string;
  second_color: string;
  favicon: string;
}

const GetClientData = async () => {
  const response = await configApi.get<IClientData>(`client/`);
  return response.data;
};

export const useGetClientData = () => {
  return useQuery({
    queryKey: ['get_client_data'],
    queryFn: () => GetClientData(),
    refetchOnMount: false,
  });
};
