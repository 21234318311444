import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface IResultExemption {
  id: string;
  name: string;
}

const RequestGetExemptionType = async (selectiveProcessId?: string) => {
  const response = await configApi.get<IResultExemption[]>('selective-process/exemption-type/', {
    params: { is_active: true, selective_process_id: selectiveProcessId },
  });
  return response.data;
};

export const useRequestGetExemptionType = (selectiveProcessId?: string) => {
  return useQuery({
    queryKey: ['get_exemption_type'],
    queryFn: () => RequestGetExemptionType(selectiveProcessId),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
