import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface IPreliminaryResult {
  notes: Note[];
  classifications: Classifications;
  step: string;
}

interface Note {
  name: string;
  value: number;
}

interface Classifications {
  general: IClass;
  pcd: IClass;
  black_or_brown: IClass;
  extensive_competition: IClass;
}

interface IClass {
  classification: string;
  status: string;
}

const preliminaryResult = async (selective_process_id: string) => {
  const response = await configApi.get<IPreliminaryResult>(`candidate-area/result/${selective_process_id}/`);
  return response.data;
};

export const usePreliminaryResult = (selective_process_id: string) => {
  return useQuery({
    queryKey: ['preliminary_result', selective_process_id],
    queryFn: () => preliminaryResult(selective_process_id),
    refetchOnWindowFocus: false,
  });
};
