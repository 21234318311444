import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface IProofSubject {
  id: string;
  name: string;
  subtitle: string;
  question_count: number;
  minimum_points_for_approval: string;
  questions_points: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}
const GetProofSubjects = async () => {
  const response = await configApi.get<IProofSubject[]>('proof/subject/');

  return response.data;
};

export const useGetProofSubjects = () => {
  return useQuery({
    queryKey: ['get_proof_subjects'],
    queryFn: GetProofSubjects,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
