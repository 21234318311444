import { FieldValues } from 'react-hook-form';
import { configApi } from '../../configApi';
import { useMutation } from '@tanstack/react-query';
import { EFormatDate, formatDate } from '../../../utils/formatData';
import { ICandidateData } from '../../../contexts/types';

interface IUpdateCandidateDataForm {
  oldData: ICandidateData;
  newData: FieldValues;
}

const requestUpdateCandidateData = async (payload: IUpdateCandidateDataForm) => {
  const updateCandidateDate = new FormData();

  if (payload.newData.email !== (payload.oldData.user?.email ?? '') || '') {
    updateCandidateDate.append('email', payload.newData.email);
  }

  if (payload.newData.address_city !== (payload.oldData.address_city?.id ?? '') || '') {
    updateCandidateDate.append('address_city', payload.newData.address_city);
  }
  if (payload.newData.address_neighborhood !== (payload.oldData.address_neighborhood ?? '') || '') {
    updateCandidateDate.append('address_neighborhood', payload.newData.address_neighborhood);
  }
  if (payload.newData.address_number !== (payload.oldData.address_number ?? '') || '') {
    updateCandidateDate.append('address_number', payload.newData.address_number);
  }
  if (payload.newData.address_state !== (payload.oldData.address_state?.id ?? '') || '') {
    updateCandidateDate.append('address_state', payload.newData.address_state);
  }
  if (payload.newData.address_street !== (payload.oldData.address_street ?? '') || '') {
    updateCandidateDate.append('address_street', payload.newData.address_street);
  }
  if (payload.newData.document_issuing_body !== (payload.oldData.document_issuing_body ?? '') || '') {
    updateCandidateDate.append('document_issuing_body', payload.newData.document_issuing_body);
  }
  if (payload.newData.document_issuing_body_uf !== (payload.oldData.document_issuing_body_uf.id ?? '') || '') {
    updateCandidateDate.append('document_issuing_body_uf', payload.newData.document_issuing_body_uf);
  }
  if (payload.newData.document_number !== (payload.oldData.document_number ?? '') || '') {
    updateCandidateDate.append('document_number', payload.newData.document_number);
  }
  if (payload.newData.document_type !== (payload.oldData.document_type?.id ?? '') || '') {
    updateCandidateDate.append('document_type', payload.newData.document_type);
  }
  if (payload.newData.education_level !== (payload.oldData.education_level?.id ?? '') || '') {
    updateCandidateDate.append('education_level', payload.newData.education_level);
  }
  if (payload.newData.full_name !== (payload.oldData.user?.full_name ?? '') || '') {
    updateCandidateDate.append('full_name', payload.newData.full_name);
  }
  if (payload.newData.gender !== (payload.oldData.gender?.id ?? '') || '') {
    updateCandidateDate.append('gender', payload.newData.gender);
  }
  if (payload.newData.marital_status !== (payload.oldData.marital_status?.id ?? '') || '') {
    updateCandidateDate.append('marital_status', payload.newData.marital_status);
  }
  if (payload.newData.mother_name !== (payload.oldData.mother_name ?? '') || '') {
    updateCandidateDate.append('mother_name', payload.newData.mother_name);
  }
  if (payload.newData.nationality !== (payload.oldData.nationality?.id ?? '') || '') {
    updateCandidateDate.append('nationality', payload.newData.nationality);
  }
  if (payload.newData.address_complement !== (payload.oldData.address_complement ?? '') || '') {
    updateCandidateDate.append('address_complement', payload.newData.address_complement);
  }
  if (payload.newData.address_zip_code !== (payload.oldData.address_zip_code ?? '') || '') {
    updateCandidateDate.append('address_zip_code', payload.newData.address_zip_code);
  }
  if (payload.newData.cellphone !== (payload.oldData.cellphone ?? '') || '') {
    updateCandidateDate.append('cellphone', payload.newData.cellphone.replace(/\D/g, ''));
  }
  if (payload.newData.city_naturality !== (payload.oldData.city_of_birth?.id ?? '') || '') {
    updateCandidateDate.append('city_of_birth', payload.newData.city_naturality);
  }
  if (payload.newData.cpf !== (payload.oldData.cpf ?? '') || '') {
    updateCandidateDate.append('cpf', payload.newData.cpf);
  }
  if (payload.newData.date_of_birth.us !== (payload.oldData.date_of_birth ?? '') || '') {
    updateCandidateDate.append('date_of_birth', payload.newData.date_of_birth);
  }
  if (
    formatDate(payload.newData.document_date_of_issuance, EFormatDate.BR) !==
      (payload.oldData.document_date_of_issuance.br ?? '') ||
    ''
  ) {
    updateCandidateDate.append('document_date_of_issuance', payload.newData.document_date_of_issuance);
  }

  if (payload.newData.phone !== (payload.oldData.phone ?? '') || '') {
    updateCandidateDate.append('phone', payload.newData.phone.replace(/\D/g, ''));
  }
  if (payload.newData.city_naturality !== (payload.oldData.city_of_birth?.id ?? '') || '') {
    updateCandidateDate.append('city_of_birth', payload.newData.city_naturality);
  }
  if (payload.newData.NIS !== (payload.oldData.nis ?? '') || '') {
    updateCandidateDate.append('nis', payload.newData.NIS);
  }

  if (updateCandidateDate.keys().next().done) {
    throw new Error('Altere alguma informação!');
  }

  const response = await configApi.patch(`candidate/update/${payload.oldData.id}/`, updateCandidateDate);

  return response.data;
};

export const UseUpdateProfileData = () => {
  return useMutation({
    mutationKey: ['update_profile'],
    mutationFn: (data: IUpdateCandidateDataForm) => requestUpdateCandidateData(data),
  });
};
