import {
  Accordion,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import MainCard from '../../../components/MainCard/Index';
import AccordionTemplate from '../../../components/AccordionTemplate/Index';
import ReactInputMask from 'react-input-mask';
import * as Yup from 'yup';
import { IoSearchSharp } from 'react-icons/io5';
import { useValidateCEP } from '../../../services/hooks/SignUp/useCepValidation';
import { useContext, useEffect, useState } from 'react';
import { useRequestGetState } from '../../../services/hooks/SignUp/useRequestGetState';
import { useRequestGetDocumentType } from '../../../services/hooks/SignUp/useRequestGetDocumentType';
import { useRequestGetGender } from '../../../services/hooks/SignUp/useRequestGetGender';
import { useRequestGetMaritalStatus } from '../../../services/hooks/SignUp/useRequestGetMaritalStatus';
import { useRequestGetNationality } from '../../../services/hooks/SignUp/useRequestGetNationality';
import { useRequestGetEducationLevel } from '../../../services/hooks/SignUp/useRequestGetEducationLevel';
import { useNavigate } from 'react-router-dom';
import { EFormatDate, formatDate } from '../../../utils/formatData';
import { validateCPF } from '../../../utils/validateCPF';
import { FieldValues, useForm } from 'react-hook-form';
import { ETypeError, switchMessageError, validateDate } from '../../../utils/validateDate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRequestGetCity } from '../../../services/hooks/SignUp/useRequestGetCity';
import { UseUpdateProfileData } from '../../../services/hooks/Profile/usePostProfileData';
import Cropper, { Area, Point } from 'react-easy-crop';
import getCroppedImg from '../../public/SignUp/getImageCrop';
import { configApi } from '../../../services/configApi';
import MessageError from '../../../components/MessageError';
import { ContextAuth } from '../../../contexts/Authentication';
import { DefaultButton } from '../../../components/ButtonDefault/Index';
import { TextXS } from '../../../components/Text/Index';

interface IReturnCroppedImage {
  base64: string;
  file?: File;
}

const Profile: React.FC = () => {
  const validateCEP = useValidateCEP();
  const toast = useToast();
  const { candidate, setCandidate } = useContext(ContextAuth);

  // States
  const [finalImage, setFinalImage] = useState<IReturnCroppedImage>();
  const [croppedImage, setCroppedImage] = useState<Area>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [firstChange, setFirstChange] = useState<boolean>(false);
  const [rotation, setRotation] = useState(0);
  const [showCrop, setShowCrop] = useState(false);
  const [city, setCity] = useState<string>();

  // request data
  const getStates = useRequestGetState();
  const getDocumentType = useRequestGetDocumentType();
  const getGender = useRequestGetGender();
  const getMaritalStatus = useRequestGetMaritalStatus();
  const getNationality = useRequestGetNationality();
  const getEducationLevel = useRequestGetEducationLevel();

  const navigate = useNavigate();

  const maxDate = formatDate(new Date(Date.now()).toLocaleString().substring(0, 10), EFormatDate.US);

  const updateProfileData = UseUpdateProfileData();

  const PersonalData = {
    full_name: Yup.string()
      .trim()
      .required('Campo Obrigatorio')
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome não pode conter caracteres especiais'),
    mother_name: Yup.string()
      .trim()
      .required('Campo Obrigatorio')
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome não pode conter caracteres especiais'),
    cpf: Yup.string()
      .test('validateCpf', 'Campo obrigatorio', (value) => {
        const cpf = value ?? '';
        const digits = cpf.replace(/\D/g, '');

        return digits.length !== 0;
      })
      .test('invalidCPF', 'CPF incompleto', (value) => {
        const cpf = value ?? '';
        const digits = cpf.replace(/\D/g, '');
        return digits.length >= 11;
      })
      .test('invalidCPF', 'Informe um CPF válido', (value) => {
        const cpf = value ?? '';
        const numbers = cpf.replace(/\D/g, '');
        if (numbers.length === 11) return validateCPF(numbers);
        else if (numbers.length > 11) return false;
        return true;
      }),
    date_of_birth: Yup.string().test('dataInvalid', (value, { createError, path }) => {
      const typeError = validateDate(value);

      if (typeError === ETypeError.noError) return true;

      return createError({
        message: switchMessageError(typeError),
        path: path,
      });
    }),
    education_level: Yup.string().required('Campo Obrigatorio'),
    gender: Yup.string().required('Campo Obrigatorio'),
    marital_status: Yup.string().required('Campo Obrigatorio'),
    document_type: Yup.string().required('Campo Obrigatorio'),
    document_number: Yup.string().required('Campo Obrigatorio'),
    document_issuing_body: Yup.string().required('Campo Obrigatorio'), //Orgão emissor
    document_issuing_body_uf: Yup.string().required('Campo Obrigatorio'),
    document_date_of_issuance: Yup.string().test('insuanceInvalid', (value, { createError, path }) => {
      const typeError = validateDate(value);

      if (typeError === ETypeError.noError) return true;

      return createError({
        message: switchMessageError(typeError),
        path: path,
      });
    }),
    nationality: Yup.string().required('Campo Obrigatorio'),

    state_naturality: Yup.string().when('nationality', {
      is: 'f343d6ec-05b1-4333-86cb-817ee45446a7',
      then: (schema) => schema.required('O campo é Obrigatorio'),
    }),

    city_naturality: Yup.string().when('nationality', {
      is: 'f343d6ec-05b1-4333-86cb-817ee45446a7',
      then: (schema) => schema.required('Campo Obrigatorio'),
    }),
  };

  const AddresAndContact = {
    address_zip_code: Yup.string().test('validateZip', 'Cep inválido', (cep) => {
      const zipCode = cep ?? '';
      const numbers = zipCode.replace(/\D/g, '');
      return !(numbers.length < 8);
    }),
    address_street: Yup.string().required('Campo Obrigatorio'),
    address_number: Yup.string().required('Campo Obrigatorio'),
    address_complement: Yup.string(),
    address_neighborhood: Yup.string().required('Campo Obrigatorio'),
    address_state: Yup.string().required('Campo Obrigatorio'),
    address_city: Yup.string().required('Campo Obrigatorio'),
    phone: Yup.string(),
    cellphone: Yup.string().required('Campo Obrigatorio'),
    NIS: Yup.string(),
    email: Yup.string().required('Campo Obrigatorio').email('Preencha um e-mail válido').lowercase(),
  };
  const schema = Yup.object().shape({
    ...PersonalData,
    ...AddresAndContact,
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    values: {
      NIS: candidate?.nis || '',
      address_neighborhood: candidate?.address_neighborhood || '',
      address_number: candidate?.address_number || '',
      address_state: candidate?.address_state?.id || '',
      address_street: candidate?.address_street || '',
      document_issuing_body: candidate?.document_issuing_body || '',
      document_issuing_body_uf: candidate?.document_issuing_body_uf?.id || '',
      document_number: candidate?.document_number || '',
      document_type: candidate?.document_type?.id || '',
      education_level: candidate?.education_level?.id || '',
      full_name: candidate?.user?.full_name || '',
      gender: candidate?.gender?.id || '',
      marital_status: candidate?.marital_status?.id || '',
      mother_name: candidate?.mother_name || '',
      nationality: candidate?.nationality?.id || '',
      address_complement: candidate?.address_complement || '',
      address_zip_code: candidate?.address_zip_code || '',
      cellphone: candidate?.cellphone || '',
      cpf: candidate?.cpf || '',
      date_of_birth: formatDate(candidate?.date_of_birth.br || '', EFormatDate.US),
      document_date_of_issuance: formatDate(candidate?.document_date_of_issuance.br || '', EFormatDate.US),
      phone: candidate?.phone || '',
      state_naturality: candidate?.city_of_birth?.state?.id || '',
      city_naturality: candidate?.city_of_birth?.id || '',
      address_city: candidate?.address_city?.id || '',
      email: candidate?.user?.email || '',
    },
  });

  const RegisterAccount = (data: FieldValues) => {
    if (candidate && Object.keys(data).length > 0) {
      updateProfileData
        .mutateAsync({ newData: data, oldData: candidate })
        .then((updated) => {
          setCandidate(updated);
          toast({
            title: 'Sucesso',
            description: 'Dados atualizados com sucesso!',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: (error?.message == 'Altere alguma informação!' && 'Atenção') || 'Erro',
            description:
              (error?.message == 'Altere alguma informação!' && error?.message) ||
              error?.response?.data?.message ||
              'Erro ao solicitar a atualização dos dados!',
            status: (error?.message == 'Altere alguma informação!' && 'warning') || 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    }
  };

  const getCity = useRequestGetCity(watch('address_state'));

  const getNaturality = useRequestGetCity(watch('state_naturality'));

  const checkCep = async () => {
    // const validationZipCode = await trigger('address_zip_code');
    // eslint-disable-next-line no-constant-condition
    if (true) {
      const cep = getValues('address_zip_code');
      validateCEP.mutateAsync(cep).then((data) => {
        if (data?.erro) {
          toast({
            title: 'Erro',
            description: 'CEP não encontrado',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        } else {
          getStates?.data?.forEach((state) => {
            if (state.initials == data?.uf) {
              setValue('address_state', state.id || '');
            }
          });

          setValue('address_street', data?.logradouro || '');

          setValue('address_neighborhood', data?.bairro || '');

          setCity(data?.localidade);
        }
      });
    }
  };

  const getIdNationality = (): boolean => {
    const nationality_id = watch('nationality');
    let response = false;
    getNationality.data?.forEach((nationality) => {
      if (nationality.id == nationality_id) {
        response = nationality.name.toLowerCase().includes('brasil');
        return;
      }
    });

    return response;
  };

  useEffect(() => {
    if (getCity.data) {
      if (getCity.data.length > 0 && !firstChange && getNaturality.data && getNaturality.data.length > 0) {
        setValue('address_city', candidate?.address_city?.id || '');
        setValue('city_naturality', candidate?.city_of_birth?.id);
        setFirstChange(true);
      }
      if (city) {
        getCity.data.forEach((localCity) => {
          const normalizedCity = localCity.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

          const normalizedLocalCity = city
            ?.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          if (normalizedCity == normalizedLocalCity) {
            setValue('address_city', localCity.id);
          }
        });
      }
    }
  }, [getCity.data, getNaturality.data]);

  const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedImage(croppedAreaPixels);
  };

  const handleSaveImage = async () => {
    try {
      if (finalImage && croppedImage) {
        const croppedImg = (await getCroppedImg(finalImage.base64, croppedImage, rotation)) as IReturnCroppedImage;
        if (croppedImg.file && candidate) {
          const fdImage = new FormData();
          fdImage.append('avatar', croppedImg.file);
          configApi
            .patch(`candidate/update/${candidate.id}/`, fdImage)
            .then(() => {
              setCandidate((value) => {
                if (value) {
                  return { ...value, user: { ...value.user, avatar: croppedImg.base64 } };
                }

                return value;
              });
              toast({
                title: 'Sucesso',
                description: 'Imagem atualiza com sucesso!',
                status: 'success',
                duration: 4000,
                isClosable: true,
              });
            })
            .catch((error) => {
              toast({
                title: 'Erro',
                description: error?.response?.data?.message || 'Dados atualizados com sucesso!',
                status: 'error',
                duration: 4000,
                isClosable: true,
              });
            });
        }
        setFinalImage((value) => ({ ...value, base64: croppedImg.base64 }));
        setShowCrop(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loading =
    getGender.isLoading ||
    getMaritalStatus.isLoading ||
    getDocumentType.isLoading ||
    getEducationLevel.isLoading ||
    getNationality.isLoading;

  return (
    <>
      {validateCEP.isPending && (
        <Flex position={'absolute'} h="100dvh" w="100dvw" zIndex={999} justify="center" align="center">
          <Box position="absolute" bg="black" opacity={0.6} h="100%" w="100%"></Box>
          <Stack zIndex={999} color="white" align="center" gap={5}>
            <Spinner size="xl" />
            <TextXS>Carregando endereço</TextXS>
          </Stack>
        </Flex>
      )}

      <MainCard title="ÁREA DO CANDIDATO " subtitle="- MEUS DADOS">
        {(loading && (
          <Stack flex={1} justify="center" align="center">
            <Spinner size="lg" />
          </Stack>
        )) || (
          <Accordion
            as="form"
            onSubmit={handleSubmit(RegisterAccount)}
            px={['10px', '10px', '10px', '50px', '50px']}
            gap={2}
            defaultIndex={[0, 1, 2]}
            allowMultiple
            display="flex"
            flexDir="column"
          >
            <AccordionTemplate
              border="1px solid"
              borderColor="primaryColor"
              borderRadius={6}
              gapPanel={5}
              title="DADOS PESSOAIS"
            >
              <Stack align="center" mt={5}>
                {(showCrop && (
                  <Stack w="20%" h="450px" position="relative">
                    <Stack flex={1}>
                      <Box position="absolute" top={0} left={0} right={0} bottom={210}>
                        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                          <Cropper
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            cropShape="round"
                            aspect={1 / 1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            image={finalImage?.base64}
                          />
                        </Box>
                      </Box>
                    </Stack>
                    <Stack bg={''} px={5} py={3}>
                      <Stack>
                        <TextXS>Zoom</TextXS>
                        <Input
                          margin={0}
                          p={0}
                          className="range"
                          type="range"
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          h="2px"
                          borderColor="colorLink"
                          value={zoom}
                          onChange={(e) => {
                            setZoom(parseFloat(e.target.value));
                          }}
                        />
                      </Stack>
                      <Stack>
                        <TextXS>Girar Imagem</TextXS>
                        <Input
                          margin={0}
                          p={0}
                          className="range"
                          type="range"
                          min={0}
                          max={360}
                          step={10}
                          h="2px"
                          borderColor="colorLink"
                          value={rotation}
                          onChange={(e) => {
                            setRotation(parseFloat(e.target.value));
                          }}
                        />
                      </Stack>
                      <Button onClick={handleSaveImage} mt={5} bg="primaryColor" color="white">
                        Salvar imagem
                      </Button>
                      <Button
                        onClick={() => {
                          setShowCrop(false);
                          setFinalImage(undefined);
                        }}
                        borderColor="primaryColor"
                        border="1px solid"
                        bg="transparent"
                      >
                        Cancelar
                      </Button>
                    </Stack>
                  </Stack>
                )) || (
                  <></>
                  // <Stack w="15%" minW="150px" position="relative" align="center">
                  //   <Avatar
                  //     name={candidate?.user?.full_name}
                  //     size="2xl"
                  //     objectFit="cover"
                  //     src={finalImage?.base64 || candidate?.user?.avatar || ''}
                  //   />
                  //   <TextXS
                  //     onClick={() => navigate('/cadastro-foto')}
                  //     color="colorLink"
                  //     cursor="pointer"
                  //     textAlign="center"
                  //   >
                  //     Alterar foto
                  //   </TextXS>
                  // </Stack>
                )}
              </Stack>
              <Flex mt={5} gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl isReadOnly w={['100%', '100%', '100%', '25%', '25%']} isRequired>
                  <FormLabel>CPF:</FormLabel>
                  <Input isRequired={false} {...register('cpf')} as={ReactInputMask} mask="999.999.999-99" />
                  <MessageError>{errors.cpf?.message}</MessageError>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Nome completo:</FormLabel>
                  <Input isRequired={false} {...register('full_name')} />
                  <MessageError>{errors.full_name?.message}</MessageError>
                </FormControl>
                <FormControl isRequired w={['100%', '100%', '100%', '25%', '25%']}>
                  <FormLabel>Data de nascimento:</FormLabel>
                  <Input max={maxDate} type="date" isRequired={false} {...register('date_of_birth')} />
                  <MessageError>{errors.date_of_birth?.message}</MessageError>
                </FormControl>
              </Flex>

              <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl isRequired>
                  <FormLabel>Nome da mãe:</FormLabel>
                  <Input isRequired={false} {...register('mother_name')} />
                  <MessageError>{errors.mother_name?.message}</MessageError>
                </FormControl>
              </Flex>

              <Flex gap={5} flexDir={['column', 'column', 'column', 'column', 'row']}>
                <FormControl isRequired>
                  <FormLabel>Tipo de documento:</FormLabel>
                  <Select isRequired={false} {...register('document_type')}>
                    <option value="" hidden>
                      Selecione
                    </option>
                    {getDocumentType.data?.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Select>
                  <MessageError>{errors.document_type?.message}</MessageError>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Número do documento:</FormLabel>
                  <Input isRequired={false} {...register('document_number')} />
                  <MessageError>{errors.document_number?.message}</MessageError>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Data de expedição:</FormLabel>
                  <Input type="date" isRequired={false} {...register('document_date_of_issuance')} />
                  <MessageError>{errors.document_date_of_issuance?.message}</MessageError>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Órgão:</FormLabel>
                  <Input isRequired={false} {...register('document_issuing_body')} />
                  <MessageError>{errors.document_issuing_body?.message}</MessageError>
                </FormControl>

                <FormControl w={['100%', '100%', '100%', '50%', '50%']} isRequired>
                  <FormLabel>UF:</FormLabel>
                  <Select isRequired={false} {...register('document_issuing_body_uf')}>
                    <option value={''} hidden>
                      Selecione
                    </option>
                    {getStates?.data?.map((state) => (
                      <option value={state.id} key={state.id}>
                        {state.initials}
                      </option>
                    ))}
                  </Select>
                  <MessageError>{errors.document_issuing_body_uf?.message}</MessageError>
                </FormControl>
              </Flex>

              <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl>
                  <FormLabel>NIS:</FormLabel>
                  <Input isRequired={false} {...register('NIS')} />
                  <MessageError>{errors.NIS?.message}</MessageError>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Escolaridade:</FormLabel>
                  <Select isRequired={false} {...register('education_level')}>
                    <option value="" hidden>
                      Selecione
                    </option>
                    {getEducationLevel?.data?.map((educationLevel) => (
                      <option key={educationLevel.id} value={educationLevel.id}>
                        {educationLevel.name}
                      </option>
                    ))}
                  </Select>
                  <MessageError>{errors.education_level?.message}</MessageError>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Gênero:</FormLabel>
                  <Select isRequired={false} {...register('gender')}>
                    <option value="" hidden>
                      Selecione
                    </option>
                    {getGender?.data?.map((gender) => (
                      <option key={gender.id} value={gender.id}>
                        {gender.name}
                      </option>
                    ))}
                  </Select>
                  <MessageError>{errors.gender?.message}</MessageError>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Estado civil:</FormLabel>
                  <Select isRequired={false} {...register('marital_status')}>
                    <option value="" hidden>
                      Selecione
                    </option>
                    {getMaritalStatus?.data?.map((maritalStatus) => (
                      <option key={maritalStatus.id} value={maritalStatus.id}>
                        {maritalStatus.name}
                      </option>
                    ))}
                  </Select>
                  <MessageError>{errors.marital_status?.message}</MessageError>
                </FormControl>
              </Flex>

              <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl isRequired>
                  <FormLabel>Nacionalidade:</FormLabel>
                  <Select
                    isRequired={false}
                    {...register('nationality', {
                      onChange: () => {
                        if (!getIdNationality()) {
                          setValue('city_naturality', '');
                          setValue('state_naturality', '');
                        }
                      },
                    })}
                  >
                    <option value="" hidden>
                      Selecione
                    </option>
                    {getNationality.data?.map((nationality) => (
                      <option key={nationality.id} value={nationality.id}>
                        {nationality.name}
                      </option>
                    ))}
                  </Select>
                  <MessageError>{errors.nationality?.message}</MessageError>
                </FormControl>
                {getIdNationality() && (
                  <>
                    <FormControl isRequired>
                      <FormLabel>Naturalidade (estado):</FormLabel>
                      <Select isRequired={false} {...register('state_naturality')}>
                        <option value="" hidden>
                          Selecione
                        </option>
                        {getStates.data?.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                      </Select>
                      <MessageError>{errors.state_naturality?.message}</MessageError>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Naturalidade (cidade):</FormLabel>
                      <Select
                        isDisabled={!watch('state_naturality')}
                        isRequired={false}
                        {...register('city_naturality')}
                      >
                        <option value="" hidden>
                          Selecione
                        </option>
                        {getNaturality.data?.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </Select>
                      <MessageError>{errors.city_naturality?.message}</MessageError>
                    </FormControl>
                  </>
                )}
              </Flex>
            </AccordionTemplate>

            {/* <AccordionTemplate display="flex" flexDir="column" gap={5} title="DADOS ADICIONAIS"></AccordionTemplate> */}

            <AccordionTemplate
              border="1px solid"
              borderColor="primaryColor"
              borderRadius={6}
              gapPanel={5}
              display="flex"
              flexDir="column"
              title="ENDEREÇO E CONTATO"
            >
              <Flex mt={5} gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl w={['100%', '100%', '100%', '30%', '30%']} isRequired>
                  <FormLabel>CEP:</FormLabel>
                  <Flex>
                    <Input
                      isRequired={false}
                      borderTopRightRadius={0}
                      borderBottomRightRadius={0}
                      {...register('address_zip_code')}
                      as={ReactInputMask}
                      mask="99999-999"
                      borderRight="none"
                    />

                    <Button onClick={checkCep} bg="gray.200" borderTopLeftRadius={0} borderBottomLeftRadius={0}>
                      <IoSearchSharp size={30} />
                    </Button>
                  </Flex>
                  <MessageError>{errors.address_zip_code?.message}</MessageError>
                </FormControl>
                <FormControl w={['100%', '100%', '100%', '70%', '70%']} isRequired>
                  <FormLabel>Endereço:</FormLabel>
                  <Input isRequired={false} {...register('address_street')} />
                  <MessageError>{errors.address_street?.message}</MessageError>
                </FormControl>
              </Flex>

              <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl w={['100%', '100%', '100%', '30%', '30%']} isRequired>
                  <FormLabel>Número:</FormLabel>
                  <Input isRequired={false} {...register('address_number')} />
                  <MessageError>{errors.address_number?.message}</MessageError>
                </FormControl>

                <FormControl w={['100%', '100%', '100%', '30%', '30%']}>
                  <FormLabel>Complemento:</FormLabel>
                  <Input {...register('address_complement')} />
                  <MessageError>{errors.address_complement?.message}</MessageError>
                </FormControl>

                <FormControl w={['100%', '100%', '100%', '30%', '30%']} isRequired>
                  <FormLabel>Bairro:</FormLabel>
                  <Input isRequired={false} {...register('address_neighborhood')} />
                  <MessageError>{errors.address_neighborhood?.message}</MessageError>
                </FormControl>
              </Flex>

              <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl w={['100%', '100%', '100%', '50%', '50%']} isRequired>
                  <FormLabel>UF:</FormLabel>
                  <Select isRequired={false} {...register('address_state')}>
                    <option value={''} hidden>
                      Selecione
                    </option>
                    {getStates?.data?.map((state) => (
                      <option value={state.id} key={state.id}>
                        {state.initials}
                      </option>
                    ))}
                  </Select>
                  <MessageError>{errors.address_state?.message}</MessageError>
                </FormControl>
                <FormControl w={['100%', '100%', '100%', '50%', '50%']} isRequired>
                  <FormLabel>Cidade:</FormLabel>
                  <Select isDisabled={!watch('address_state')} isRequired={false} {...register('address_city')}>
                    <option value={''} hidden>
                      Selecione
                    </option>
                    {getCity?.data?.map((city) => (
                      <option value={city.id} key={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </Select>
                  <MessageError>{errors.address_city?.message}</MessageError>
                </FormControl>
              </Flex>
              <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl w={['100%', '100%', '100%', '50%', '50%']}>
                  <FormLabel>Telefone:</FormLabel>
                  <Input as={ReactInputMask} mask={'(99) 9999-9999'} {...register('phone')} />
                </FormControl>
                <FormControl w={['100%', '100%', '100%', '50%', '50%']}>
                  <FormLabel>Celular:</FormLabel>
                  <Input as={ReactInputMask} maskChar={''} mask={'(99) 99999-9999'} {...register('cellphone')} />
                  <MessageError>{errors.cellphone?.message}</MessageError>
                </FormControl>
              </Flex>
              <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
                <FormControl isRequired w={['100%', '100%', '100%', '50%', '50%']}>
                  <FormLabel>Email:</FormLabel>
                  <Input isRequired={false} type="email" {...register('email')} />
                </FormControl>
              </Flex>
            </AccordionTemplate>

            <Stack flexDir="row" justify="flex-end" mt={10} mb={10}>
              <DefaultButton bg="lightGrey" color="black" onClick={() => navigate(-1)} label="Cancelar" />
              <DefaultButton
                isLoading={updateProfileData?.isPending}
                bg="primaryColor"
                color="white"
                type="submit"
                label="Atualizar Cadastro"
                maxW="fit-content"
              />
            </Stack>
          </Accordion>
        )}
      </MainCard>
    </>
  );
};

export default Profile;
