import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { IDataToRegistration } from '../CandidateArea/useGetDataToRegistration';
import { FieldValues } from 'react-hook-form';
import { IStatusProps } from '../SelectiveProcess/Types';
import { ISelectiveProcessVancancy } from '../SelectiveProcess/useRequestSelectiveProcessVacancy';
import { ETypeOfCompetition } from '../../../components/pages/Registration/DefaultVacancy';

interface ISpecialConditionList {
  id: string;
  observations: string;
  social_name: string;
}

interface IMutationCreateRegistration {
  data: FieldValues;
  listTypeCompetition?: ISelectiveProcessVancancy[];
  selectiveProcess?: IDataToRegistration;
  registrationId?: string;
}

interface IExemptionPayload {
  type?: string;
  nis?: string;
  document_number?: string;
  document_issuing_body?: string;
  document_issuing_body_uf?: string;
  mother_name?: string;
}

const requestCreateRegistration = async ({
  data,
  selectiveProcess,
  listTypeCompetition = [],
  registrationId,
}: IMutationCreateRegistration) => {
  const listToCreate = new FormData();
  const exemptionObject: IExemptionPayload = {};

  if (data?.type_exemption) {
    const typeExemptionObject = JSON.parse(data?.type_exemption);

    if (typeExemptionObject.name.toLowerCase().includes('cadúnico')) {
      if (data?.nis) {
        exemptionObject.nis = data.nis;
      }

      if (data?.mother_name) {
        exemptionObject.mother_name = data.mother_name;
      }

      if (data?.document_number) {
        exemptionObject.document_number = data.document_number;
      }

      if (data?.document_issuing_body) {
        exemptionObject.document_issuing_body = data.document_issuing_body;
      }

      if (data?.document_issuing_body_uf) {
        exemptionObject.document_issuing_body_uf = data.document_issuing_body_uf;
      }
    } else {
      if (data?.attachmentExemption?.length > 0) {
        for (let index = 0; index < data.attachmentExemption.length; index++) {
          listToCreate.append('exemption_file', data.attachmentExemption[index]);
        }
      }
    }

    exemptionObject.type = typeExemptionObject.id;
  }

  if (exemptionObject) {
    listToCreate.append('exemption', JSON.stringify(exemptionObject));
  }

  if (selectiveProcess?.allows_special_condition && data.has_special_condition) {
    const isSpecialCondition = data.has_special_condition === 'yes';

    listToCreate.append('is_special_condition', `${isSpecialCondition}`);

    if (isSpecialCondition) {
      const specialConditionsIds: ISpecialConditionList[] = [];
      data.selectedSpecialCondition.forEach((value: boolean, index: number) => {
        if (value === false) return false;

        selectiveProcess?.special_condition.forEach((specialCondition) => {
          if (specialCondition.id === value.toString() && specialCondition.has_attachment) {
            const attach = data?.attachmentFile[index];
            if (attach && attach?.length > 0) {
              for (let index = 0; index < attach.length; index++) {
                listToCreate.append(`attachment_${value}`, attach[index]);
              }
            }
          }
        });

        specialConditionsIds.push({
          id: `${value}`,
          observations: data.observation[index],
          social_name: data.social_name[index],
        });
      });
      listToCreate.append('special_conditions_ids', JSON.stringify(specialConditionsIds));
    }
  }

  if (data.has_second_vacancy) {
    listToCreate.append('second_vacancy', data.initial_semester_secondary);
  } else {
    listToCreate.append('second_vacancy', '');
  }

  if (data.cid && data.deficiency && data.type_of_competition == ETypeOfCompetition.SPECIAL_VACANCY) {
    listToCreate.append('reserved_vacancy_id', JSON.stringify({ cid: data.cid, deficiency: data.deficiency }));
  }

  // if (data.deficiency && data.type_of_competition == ETypeOfCompetition.SPECIAL_VACANCY) {
  //   listToCreate.append('deficiency', data.deficiency);
  // }

  if (data.vacancy) {
    listToCreate.append('selective_process_vacancy', data.vacancy);
  }

  // if (data.vagancy_type === 'is_initial_semester') {
  //   listToCreate.append('selective_process_vacancy', data.initial_semester);
  // }

  // if (data.vagancy_type === 'is_level_test') {
  //   listToCreate.append('selective_process_vacancy', data.nivel_test);
  // }

  if (
    data.modality_type &&
    data.modality_type.length > 0 &&
    data.type_of_competition == ETypeOfCompetition.SPECIAL_VACANCY
  ) {
    const modalityType = data.modality_type
      .filter((type: boolean) => type !== false)
      .map((value: string) => {
        const modality: IStatusProps = JSON.parse(value);
        if (modality?.name?.toLowerCase().includes('pcd')) {
          if (data?.medical_report && data?.medical_report?.length > 0) {
            for (let idxFile = 0; idxFile < data.medical_report.length; idxFile++) {
              listToCreate.append('medical_report_files', data.medical_report[idxFile]);
            }
          }
        }
        return modality.id;
      });
    if (data.type_of_competition === ETypeOfCompetition.FULL_VACANCY) {
      let full_vacancy_id = '';
      listTypeCompetition?.forEach((item) => {
        item.type_of_competition.forEach((type) => {
          if (type.name.toLowerCase().includes('ampla')) {
            full_vacancy_id = type.id;
            return;
          }
        });
      });
      listToCreate.append('type_of_competition', JSON.stringify([full_vacancy_id]));
    } else {
      listToCreate.append('type_of_competition', JSON.stringify(modalityType));
    }
  }

  if (data.is_all) {
    listToCreate.append('type_of_competition', JSON.stringify([data.type_of_competition]));
  }

  if (selectiveProcess?.firearms_act) {
    const weaponCarryPermit = data.weapon_carry_permit === 'yes';
    listToCreate.append('weapon_carry_permit', `${weaponCarryPermit}`);
  }

  if (selectiveProcess?.juror_under_article_440) {
    const jurorArt = data.juror_art_440_cpp === 'yes';
    listToCreate.append('juror_art_440_cpp', `${jurorArt}`);
  }

  if (data.terms) {
    listToCreate.append('according_to_the_terms', data.terms);
  } else {
    listToCreate.append('according_to_the_terms', data.terms);
  }

  if (registrationId) {
    const response = await configApi.patch(`registration/update/${registrationId}/`, listToCreate);

    return response.data;
  }

  if (!registrationId) {
    const response = await configApi.post(`registration/create/`, listToCreate);

    return response.data;
  }
};

export const useCreateRegistration = () => {
  return useMutation({
    mutationKey: ['create_registration'],
    mutationFn: (payload: IMutationCreateRegistration) => requestCreateRegistration(payload),
  });
};
