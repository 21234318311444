import { Stack, Flex, Box } from '@chakra-ui/react';
import { TextL, TextS, TextXS, TextXXS } from '../Text/Index';

interface IOwnProps {
  bgColor?: string;
  color?: string;
  classification: string;
  status?: string;
  title: string;
}

const ClassificationBox: React.FC<IOwnProps> = ({
  bgColor = 'linear-gradient(135deg, #002E5B, #004080)',
  color = 'white',
  classification,
  status,
  title,
}) => {
  return (
    <Stack
      w="250px"
      h="fit-content"
      minH="100px"
      bg={bgColor}
      color={color}
      borderRadius={4}
      boxShadow="lg"
      spacing={1}
      textAlign="center"
      align="center"
      justify="center"
      py={1}
    >
      <Box>
        <TextS fontWeight="bold">CLASSIFICAÇÃO</TextS>
        <TextXS opacity={0.85} mt={1}>
          {title}
        </TextXS>
      </Box>
      <Flex justifyContent="center" alignItems="center" flex={1}>
        <TextL>{`${classification}º`}</TextL>
      </Flex>

      <TextXXS textTransform="uppercase" mb={3} letterSpacing="wide">
        {status ? status : '-'}
      </TextXXS>
    </Stack>
  );
};

export default ClassificationBox;
