import { Spinner, Stack } from '@chakra-ui/react';
import HistoryRegistration from './HistoryRegistration/Index';

import Impugnation from './Impugnation/Index';
import { useRequestMyRegistration } from '../../../services/hooks/CandidateArea/useRequestMyRegistration';
import { RiSignalWifiErrorLine } from 'react-icons/ri';
import MainCard from '../../../components/MainCard/Index';
import { TextS } from '../../../components/Text/Index';
//import { useLogsUser } from '../../../services/hooks/LogsUser/useLogsUser';

const CandidateArea: React.FC = () => {
  const { error, isLoading } = useRequestMyRegistration();
  //useLogsUser('detalhes da inscrição');

  return (
    <MainCard height="100%" title="ÁREA DO CANDIDATO" subtitle="- MINHAS INSCRIÇÕES">
      {(isLoading && (
        <Stack height="100%" justify="center" align="center">
          <Spinner size="lg" />
        </Stack>
      )) ||
        (error && (
          <Stack height="100%" color="red" justify="center" align="center">
            <RiSignalWifiErrorLine size={50} />
            <TextS fontWeight="bold" fontSize="16px">
              Erro ao trazer os dados!
            </TextS>
          </Stack>
        )) || (
          <>
            <HistoryRegistration />
            <Impugnation />
          </>
        )}
    </MainCard>
  );
};

export default CandidateArea;
