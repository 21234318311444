import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import MainCard from '../../../../../components/MainCard/Index';
import TableCustom from '../../../../../components/pages/CandidateArea/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IReserved_vacancy,
  ISpecialConditionList,
  useGetRegistration,
} from '../../../../../services/hooks/Registration/useGetRegistration';
import ProofRegistrationButton from '../../../../../components/PDFs/DownloadPDFButton/proofRegistrationButton';
import { IoMdPrint } from 'react-icons/io';
import { useRef, useState } from 'react';
// import { ContextAuth } from '../../../../../contexts/Authentication';
import { TextM, TextXS } from '../../../../../components/Text/Index';
import { DefaultButton } from '../../../../../components/ButtonDefault/Index';
import { badgeColor } from '../../../../../utils/badgeFunction';

const MoreDetail: React.FC = () => {
  const navigate = useNavigate();
  const reservedVacancies = ['Tipo', 'Situação', 'Ação'];
  const headerSpecialCondition = ['TIPO DE SOLICITAÇÃO', 'Situação', 'Ação'];
  const registrationId = useLocation().state as string;
  const getRegistration = useGetRegistration(registrationId);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoadFilePrint, setIsLoadFilePrint] = useState<boolean>(true);
  const [generatedPrint, setGeneratedPrint] = useState<boolean>(false);
  // const { candidate } = useContext(ContextAuth);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedReserved, setSelectedReserved] = useState<IReserved_vacancy>();

  const [selectedSpecialCondition, setSelectedSpecialCondition] = useState<ISpecialConditionList>();

  const handleImprimir = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      if (!generatedPrint) {
        const spn = iframe.contentWindow?.document.getElementById('selective-process-name');
        const rn = iframe.contentWindow?.document.getElementById('registration-number');
        const rd = iframe.contentWindow?.document.getElementById('registration-date');
        const name = iframe.contentWindow?.document.getElementById('name');
        const cpf = iframe.contentWindow?.document.getElementById('cpf');
        const idoc = iframe.contentWindow?.document.getElementById('idoc');
        const gender = iframe.contentWindow?.document.getElementById('gender');
        const dob = iframe.contentWindow?.document.getElementById('dob');
        const address = iframe.contentWindow?.document.getElementById('address');
        const exemption = iframe.contentWindow?.document.getElementById('exemption');
        const status = iframe.contentWindow?.document.getElementById('rs');
        const contact = iframe.contentWindow?.document.getElementById('contacts');
        const payment_status = iframe.contentWindow?.document.getElementById('payment_status');
        const pcd = iframe.contentWindow?.document.getElementById('pcd');
        const special_condition = iframe.contentWindow?.document.getElementById('special_condition');
        const type_of_competition = iframe.contentWindow?.document.getElementById('type_of_competition');
        const spv1 = iframe.contentWindow?.document.getElementById('selective-process-vacancy');
        const spvLabel1 = iframe.contentWindow?.document.getElementById('spvLabel1');
        const spvLabel2 = iframe.contentWindow?.document.getElementById('spvLabel2');
        const spv2 = iframe.contentWindow?.document.getElementById('selective-process-second-vacancy');
        const title = iframe.contentWindow?.document.getElementById('title');

        if (title) {
          title.innerText += `${getRegistration.data?.exemptions && getRegistration.data?.exemptions?.length > 0 ? 'COMPROVANTE DE ISENÇÃO' : 'COMPROVANTE DE INSCRIÇÃO'}`;
        }

        if (spn) {
          spn.innerText += ` ${getRegistration.data?.selective_process_vacancy?.selective_process?.name || ''}`;
        }

        if (spv1) {
          spv1.innerText += ` ${getRegistration.data?.selective_process_vacancy?.name || ''}`;
        }

        if (spvLabel1) {
          if (
            getRegistration.data?.selective_process_vacancy?.cultural_house &&
            getRegistration.data?.selective_process_vacancy.class_level
          ) {
            spvLabel1.innerText = `Turma:  `;
          } else if (
            getRegistration.data?.selective_process_vacancy?.cultural_house &&
            !getRegistration.data?.selective_process_vacancy?.class_level
          ) {
            spvLabel1.innerText = `Turma - primeira opção: `;
          } else {
            spvLabel1.innerText = `Vaga: `;
          }
        }
        if (spvLabel2) {
          if (getRegistration.data?.second_vacancy) {
            spvLabel2.innerText = `Turma - segunda opção: `;
          } else {
            spvLabel2.innerText = ``;
          }
        }

        if (spv2) {
          spv2.innerText += `${getRegistration.data?.second_vacancy?.name || ''}`;
        }

        if (rn) {
          rn.innerText += ` ${getRegistration.data?.registration_code || ''}`;
        }

        if (rd) {
          rd.innerText += ` ${getRegistration.data?.created_at.br || ''}`;
        }

        if (name) {
          name.innerText += ` ${getRegistration.data?.candidate?.user?.full_name || ''}`;
        }

        if (cpf) {
          cpf.innerText += ` ${getRegistration.data?.candidate?.cpf || ''}`;
        }

        if (idoc) {
          idoc.innerText += `${getRegistration.data?.candidate?.document_type?.name || ''}: ${getRegistration.data?.candidate?.document_number || ''} - ${getRegistration.data?.candidate?.document_issuing_body || ''} - ${getRegistration.data?.candidate?.document_issuing_body_uf.initials || ''} - expedido: ${getRegistration.data?.candidate?.document_date_of_issuance.br || ''}`;
        }

        if (gender) {
          gender.innerText += ` ${getRegistration.data?.candidate?.gender?.name || ''}`;
        }

        if (dob) {
          dob.innerText += ` ${getRegistration.data?.candidate?.date_of_birth.br || ''}`;
        }

        if (address) {
          address.innerText += ` ${getRegistration.data?.candidate.address_street || '--'}, N° ${getRegistration.data?.candidate.address_number || '--'} - ${getRegistration.data?.candidate.address_complement}, ${getRegistration.data?.candidate.address_neighborhood || '--'} - CEP: ${getRegistration.data?.candidate.address_zip_code || '--'} - ${getRegistration.data?.candidate.address_city?.name || '--'}/${getRegistration.data?.candidate.address_state?.initials || '--'}`;
        }

        if (exemption) {
          exemption.innerText += ` ${getRegistration.data?.is_exemption ? 'Sim' : 'Não'}`;
        }

        if (status) {
          status.innerText += ` ${getRegistration.data?.registration_status?.name || ''}`;
        }

        if (type_of_competition) {
          getRegistration?.data?.type_of_competition?.forEach((type) => {
            const paragraph = iframe?.contentWindow?.document?.createElement('p');
            if (paragraph) {
              paragraph.style.fontSize = '12px';
              paragraph.innerText += `${type.name}: SIM `;
              type_of_competition?.appendChild(paragraph);
            }
          });
        }

        if (getRegistration.data?.candidate?.user.email) {
          const createText = iframe.contentWindow?.document.createElement('p');
          if (createText) {
            createText.style.marginLeft = '40px';
            createText.style.fontSize = '12px';
            createText.innerText += `- E-Mail: ${getRegistration.data?.candidate?.user?.email}`;
            contact?.appendChild(createText);
          }
        }

        if (getRegistration.data?.candidate?.phone) {
          const createText = iframe.contentWindow?.document.createElement('p');
          if (createText) {
            createText.style.marginLeft = '40px';
            createText.style.fontSize = '12px';
            createText.innerText += `- Telefone: ${getRegistration.data.candidate.phone}`;
            contact?.appendChild(createText);
          }
        }

        if (getRegistration.data?.candidate?.cellphone) {
          const createText = iframe.contentWindow?.document.createElement('p');
          if (createText) {
            createText.style.marginLeft = '40px';
            createText.style.fontSize = '12px';
            createText.innerText += `- Celular: ${getRegistration.data.candidate.cellphone}`;
            contact?.appendChild(createText);
          }
        }

        if (payment_status && getRegistration.data?.invoice?.status_invoice) {
          const labelInvoice = iframe?.contentWindow?.document?.createElement('p');
          const labelStatusInvoice = iframe?.contentWindow?.document?.createElement('p');

          if (labelInvoice && labelStatusInvoice) {
            labelInvoice.style.fontWeight = 'bold';
            labelInvoice.style.paddingTop = '0px';
            labelInvoice.style.paddingBottom = '0px';
            labelInvoice.style.paddingLeft = '5px';
            labelInvoice.style.fontSize = '13px';
            labelInvoice.innerText = 'SITUAÇÃO DE PAGAMENTO: ';
            labelStatusInvoice.innerText = getRegistration?.data?.invoice?.status_invoice || '';
            labelStatusInvoice.style.fontSize = '13px';

            labelStatusInvoice.style.marginLeft = '1px';
            payment_status.appendChild(labelInvoice);
            payment_status.appendChild(labelStatusInvoice);
          }
        }

        const attachmentPCD = getRegistration?.data?.reserved_vacancy.filter((attach) =>
          attach.type.toLowerCase().includes('pcd'),
        );
        if (pcd && attachmentPCD && attachmentPCD.length > 0) {
          pcd.style.display = 'flex';
          pcd.style.flexDirection = 'column';
          const createText = iframe?.contentWindow?.document?.createElement('p');
          if (createText) {
            createText.style.fontSize = '12px';
            createText.innerText = 'Anexo de documentos PCD:';
            pcd.appendChild(createText);
          }

          attachmentPCD[0]?.attachments?.forEach((item) => {
            const createSubText = iframe?.contentWindow?.document?.createElement('p');
            if (createSubText) {
              createSubText.style.fontSize = '12px';
              createSubText.style.display = 'block';
              createSubText.style.marginLeft = '40px';
              createSubText.innerText = `- ${item.name} - ${item.created_at.br} (enviado pelo ${item.sent_by_client ? 'candidato' : 'Administrador'})`;
              pcd.appendChild(createSubText);
            }
          });
        }

        const attachmentSpecialCondition = getRegistration.data?.special_conditions;
        if (special_condition && attachmentSpecialCondition && attachmentSpecialCondition.length > 0) {
          special_condition.style.display = 'flex';
          special_condition.style.flexDirection = 'column';
          const createText = iframe?.contentWindow?.document?.createElement('p');
          if (createText) {
            createText.style.fontSize = '12px';
            createText.innerText = 'Anexo de Condições Especiais:';
            special_condition.appendChild(createText);
          }

          attachmentSpecialCondition.forEach((item) => {
            const createSubText = iframe?.contentWindow?.document?.createElement('p');
            if (createSubText) {
              createSubText.style.fontSize = '12px';
              createSubText.style.display = 'block';
              createSubText.style.marginLeft = '40px';
              createSubText.innerText = item.name;
              special_condition.appendChild(createSubText);

              if (item.attachments && item.attachments.length > 0) {
                item.attachments.forEach((file) => {
                  const createSubSubText = iframe?.contentWindow?.document?.createElement('p');
                  if (createSubSubText) {
                    createSubSubText.style.fontSize = '12px';
                    createSubSubText.style.display = 'block';
                    createSubSubText.style.marginLeft = '60px';
                    createSubSubText.innerText = `- ${file.name} - ${file.created_at.br} (enviado pelo ${file.sent_by_client ? 'candidato' : 'administrador'})`;
                    special_condition.appendChild(createSubSubText);
                  }
                });
              }
            }
          });
        }
        setGeneratedPrint(true);
      }

      iframe.contentWindow?.print();
    }
  };

  const handleDetail = (reserved?: IReserved_vacancy, special?: ISpecialConditionList) => {
    if (reserved) setSelectedReserved(reserved);

    if (special) setSelectedSpecialCondition(special);

    onOpen();
  };

  const onCloseModal = () => {
    setSelectedReserved(undefined);
    setSelectedSpecialCondition(undefined);
    onClose();
  };

  return (
    <MainCard
      title="ÁREA DO CANDIDATO - "
      subtitle={`DETALHES DA INSCRIÇÃO Nº ${getRegistration?.data?.registration_code}`}
    >
      <Stack px={['10px', '10px', '10px', '50px', '50px']} gap={10}>
        <Flex
          as="iframe"
          title="Imprimir Inscrição"
          src={`${document.location.origin}/imprimir-registration`}
          ref={iframeRef}
          display="none"
          onLoad={() => setIsLoadFilePrint(false)}
        />

        <Stack boxShadow=" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
          <Stack p={5}>
            <TextM fontWeight="bold">DETALHES DA INSCRIÇÃO</TextM>
            <Divider border="1px solid" />
          </Stack>
          <Flex pb={5} justify="space-between">
            <Stack px={5}>
              <TextXS>
                <strong>Processo seletivo:</strong>{' '}
                {getRegistration.data?.selective_process_vacancy?.selective_process?.name}
              </TextXS>
              <TextXS>
                <strong>Nome completo: </strong> {getRegistration?.data?.candidate?.user?.full_name}
              </TextXS>
              {getRegistration?.data?.candidate?.social_name && (
                <TextXS>
                  <strong>Nome social: </strong> {getRegistration?.data?.candidate?.social_name}
                </TextXS>
              )}
              <TextXS>
                <strong>Inscrição: </strong>
                {getRegistration?.data?.registration_code}
              </TextXS>
              <TextXS>
                <strong>
                  {getRegistration.data?.selective_process_vacancy?.cultural_house
                    ? getRegistration.data?.selective_process_vacancy.class_level
                      ? 'Turma: '
                      : 'Turma - primeira opção: '
                    : 'Vaga: '}
                </strong>
                {getRegistration?.data?.selective_process_vacancy?.name}
              </TextXS>

              {getRegistration?.data?.second_vacancy?.cultural_house && (
                <TextXS>
                  <strong>Turma - segunda opção: </strong>
                  {getRegistration?.data?.second_vacancy?.name}
                </TextXS>
              )}

              <TextXS>
                <strong>Situação: </strong>
                {getRegistration?.data?.registration_status?.name}
              </TextXS>
              {getRegistration?.data?.observations && (
                <TextXS>
                  <strong>Observação: </strong>
                  {getRegistration?.data?.observations}
                </TextXS>
              )}

              {getRegistration?.data?.invoice?.status_invoice && (
                <TextXS>
                  <strong>Situação do boleto: </strong>
                  {getRegistration?.data?.invoice?.status_invoice}
                </TextXS>
              )}
              {!getRegistration?.data?.selective_process_vacancy?.cultural_house &&
                getRegistration?.data?.type_of_competition &&
                getRegistration?.data?.type_of_competition?.length > 0 && (
                  <TextXS>
                    <strong>Modalidade: </strong>
                    {getRegistration?.data?.type_of_competition.map((type, index) => (
                      <TextXS key={index} as="span">
                        {type.name}
                        {' | '}
                      </TextXS>
                    ))}
                  </TextXS>
                )}

              <Stack mt={4} w={['100%', '100%', 'fit-content', 'fit-content', 'fit-content']}>
                {getRegistration?.data?.show_print_registration_button && (
                  <Button
                    p={2}
                    fontSize="12px"
                    color="white"
                    bg="primaryColor"
                    whiteSpace="break-spaces"
                    h="fit-content"
                    onClick={handleImprimir}
                    leftIcon={<IoMdPrint size={23} />}
                    justifyContent="left"
                  >
                    {(isLoadFilePrint && <Spinner />) ||
                    (getRegistration?.data?.exemptions && getRegistration?.data?.exemptions?.length > 0)
                      ? 'IMPRIMIR COMPROVANTE DE ISENÇÃO'
                      : 'IMPRIMIR COMPROVANTE DE INSCRIÇÃO'}
                  </Button>
                )}

                {getRegistration?.data?.show_download_registration_button && (
                  <ProofRegistrationButton data={getRegistration.data} />
                )}

                {getRegistration?.data?.show_view_registration_button && (
                  <ProofRegistrationButton data={getRegistration.data} isModeViewer />
                )}
              </Stack>
            </Stack>
            {/* <Stack align="center" w="5%" minW="150px" position="relative" mr="50px">
              <Avatar name={candidate?.user?.full_name} src={candidate?.user?.avatar} size="2xl" objectFit="cover" />
              <TextXS onClick={() => navigate('/cadastro-foto')} color="colorLink" cursor="pointer" textAlign="center">
                Alterar foto
              </TextXS>
            </Stack> */}
          </Flex>
        </Stack>

        {getRegistration.data?.reserved_vacancy && getRegistration.data?.reserved_vacancy.length > 0 && (
          <Stack boxShadow=" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
            <Stack p={5}>
              <TextM fontWeight="bold">SOLICITAÇÕES PARA VAGAS RESERVADAS</TextM>
              <Divider border="1px solid" />
            </Stack>
            <Stack px={5} pb={10}>
              <TableCustom headers={reservedVacancies}>
                {getRegistration?.data?.reserved_vacancy.map((reserved) => (
                  <Tr key={reserved.id} textAlign="center">
                    <Td textAlign="center">
                      <TextXS>{reserved?.type}</TextXS>
                    </Td>
                    <Td textAlign="center">
                      <Badge
                        borderRadius={4}
                        textAlign="center"
                        lineHeight="normal"
                        p={1}
                        px={2}
                        bg={badgeColor(reserved?.status)?.bgColor || 'black'}
                        color={badgeColor(reserved?.status)?.color || 'white'}
                      >
                        {reserved?.status}
                      </Badge>
                    </Td>

                    <Td textAlign="center">
                      <Text cursor="pointer" color="colorLink" onClick={() => handleDetail(reserved)}>
                        Detalhes
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </TableCustom>
            </Stack>
          </Stack>
        )}

        {getRegistration?.data?.special_conditions && getRegistration?.data?.special_conditions?.length > 0 && (
          <Stack boxShadow=" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
            <Stack p={5}>
              <TextM fontWeight="bold">SOLICITAÇÕES DE ATENDIMENTO ESPECIAL</TextM>
              <Divider border="1px solid" />
            </Stack>
            <Stack px={5} pb={10}>
              <TableCustom headers={headerSpecialCondition}>
                {getRegistration?.data?.special_conditions.map((special) => (
                  <Tr key={special.id} textAlign="center">
                    <Td textAlign="center">
                      <TextXS>{special?.name}</TextXS>
                    </Td>
                    <Td textAlign="center">
                      <Badge
                        borderRadius={4}
                        textAlign="center"
                        lineHeight="normal"
                        p={1}
                        px={2}
                        bg={badgeColor(special?.status)?.bgColor || 'black'}
                        color={badgeColor(special?.status)?.color || 'white'}
                      >
                        {special?.status}
                      </Badge>
                    </Td>

                    <Td textAlign="center">
                      <Text cursor="pointer" color="colorLink" onClick={() => handleDetail(undefined, special)}>
                        Detalhes
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </TableCustom>
            </Stack>
          </Stack>
        )}

        <Stack mb={10} alignItems="flex-end">
          <DefaultButton
            onClick={() => {
              navigate('/painel');
            }}
            label="Voltar"
          />
        </Stack>
      </Stack>

      <Modal isOpen={isOpen} onClose={onCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent pb={10} maxW={['80%', '80%', '80%', '40%', '40%']} maxH="none">
          <ModalHeader textAlign="center">Detalhes do pedido</ModalHeader>
          <ModalCloseButton />

          {selectedReserved && (
            <ModalBody display="flex" flexDir="column" gap={1}>
              <TextXS>
                <strong>Tipo: </strong> {selectedReserved?.type}
              </TextXS>
              {selectedReserved?.type === 'PCD - Pessoa com deficiência' && (
                <TextXS>
                  <strong>Detalhes: </strong> {selectedReserved?.deficiency || '--'}
                </TextXS>
              )}
              {selectedReserved?.type === 'PCD - Pessoa com deficiência' && (
                <TextXS>
                  <strong>CID: </strong> {selectedReserved?.cid || '--'}
                </TextXS>
              )}

              <TextXS>
                <strong>Situação da solicitação: </strong> {selectedReserved?.status}
              </TextXS>
              {selectedReserved?.justification && (
                <Flex gap={1}>
                  <TextXS>
                    <strong>Justificativa da situação: </strong>{' '}
                  </TextXS>
                  <TextXS dangerouslySetInnerHTML={{ __html: selectedReserved?.justification || '--' }} />
                </Flex>
              )}

              {selectedReserved?.attachments && (
                <Stack mt={4}>
                  <TextXS>
                    <strong>Anexos: </strong>
                  </TextXS>
                  {selectedReserved?.attachments?.length === 0 && <TextXS>Sem anexos enviados</TextXS>}

                  {selectedReserved?.attachments?.map((attach) => (
                    <Box>
                      <Link color="colorLink" href={attach?.url} target="_blank">
                        {attach.name}
                      </Link>

                      <Divider mt={1} mb={1} borderColor="black" />
                    </Box>
                  ))}
                </Stack>
              )}
            </ModalBody>
          )}

          {selectedSpecialCondition && (
            <ModalBody display="flex" flexDir="column" gap={1}>
              <TextXS>
                <strong>Tipo: </strong> {selectedSpecialCondition?.name}
              </TextXS>
              {selectedSpecialCondition?.observations && (
                <TextXS>
                  <strong>Detalhes: </strong> {selectedSpecialCondition?.observations || '--'}
                </TextXS>
              )}

              <TextXS>
                <strong>Situação da solicitação: </strong> {selectedSpecialCondition?.status}
              </TextXS>
              {selectedSpecialCondition?.justification && (
                <Flex gap={1}>
                  <TextXS>
                    <strong>Justificativa da situação: </strong>{' '}
                  </TextXS>
                  <TextXS dangerouslySetInnerHTML={{ __html: selectedSpecialCondition?.justification || '--' }} />
                </Flex>
              )}
              {!selectedSpecialCondition?.attachments && (
                <Box>
                  <TextXS>
                    <strong>Anexos: </strong>
                  </TextXS>
                  <TextXS>Sem anexos enviados</TextXS>
                </Box>
              )}
              {selectedSpecialCondition?.attachments && (
                <Stack mt={4}>
                  <TextXS>
                    <strong>Anexos: </strong>
                  </TextXS>

                  {selectedSpecialCondition?.attachments.map((attach) => (
                    <Box>
                      <Link color="colorLink" href={attach?.url} target="_blank">
                        {attach.name}
                      </Link>

                      <Divider mt={1} mb={1} borderColor="black" />
                    </Box>
                  ))}
                </Stack>
              )}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </MainCard>
  );
};

export default MoreDetail;
