import { Box, Button, Divider, Flex, Input, Spinner, Stack } from '@chakra-ui/react';

import Header from '../../../components/Header/Header';
import MenuItem from '../../../components/pages/Homepage/MenuItem';
import SelectiveProcess from '../../../components/pages/Homepage/SelectiveProcess';
import { useRequestSelectiveProcess } from '../../../services/hooks/SelectiveProcess/useRequestSelectiveProcess';
import { useRef, useState } from 'react';
import { TextM, TextS, TextXL, TextXXS } from '../../../components/Text/Index';
import { BiSupport } from 'react-icons/bi';
import { useGetClientData } from '../../../services/hooks/ClientData/useGetClientData';

const Homepage: React.FC = () => {
  const [selectedButton, setSelectedButton] = useState<string>();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [finalSearch, setFinalSearch] = useState<string>('');
  const ClientData = useGetClientData();

  const emailSuport = ClientData?.data?.suport_email;

  const { data, isLoading } = useRequestSelectiveProcess(selectedButton, finalSearch);

  return (
    <Flex height="100%" w="100%" flexDir="column" bg="white">
      <Header />

      <Stack flex={1} bg="white" flexDir="column" overflow="auto" gap={0}>
        {emailSuport && (
          <Flex
            bgColor="red"
            direction={'column'}
            color="black"
            bg="#6fbfd3"
            align="center"
            display={['flex', 'flex', 'flex', 'none', 'none']}
            textAlign="left"
            mx="10px"
          >
            <Stack textAlign="left" w="100%" p="10px" borderRadius="3px">
              <TextS fontWeight="bold">Suporte ao candidato </TextS>
              <TextXXS>
                Em caso de dúvidas, necessidade de atendimento ou sugestão, entrar em contato exclusivamente através do
                e-mail <strong>{emailSuport}</strong>
              </TextXXS>
            </Stack>
          </Flex>
        )}

        {/* mt="100px" */}
        <Box
          w="100%"
          bg="primaryColor"
          h="fit-content"
          mt={['0px', '0px', '100px', '100px', '100px']}
          position="relative"
        >
          <Stack
            display={['none', 'none', 'none', 'flex', 'flex']}
            bg="primaryColor"
            w="22%"
            h="100%"
            py="20px"
            px="20px"
            alignItems="flex-end"
          >
            <Stack>
              <Stack flexDir="row" mb="10px">
                <Input h="33px" bg="white" placeholder="Busca..." ref={searchInputRef} />
                <Button
                  _hover={{ bg: '#333333', color: 'white' }}
                  color="white"
                  bg="secondaryColor"
                  h="33px"
                  px={6}
                  fontSize={14}
                  onClick={() => {
                    if (searchInputRef.current) setFinalSearch(searchInputRef.current.value);
                  }}
                >
                  Pesquisar
                </Button>
              </Stack>
              <MenuItem selectedButton={selectedButton} selectButton={setSelectedButton} label="INSCRIÇÕES ABERTAS" />
              <MenuItem selectedButton={selectedButton} selectButton={setSelectedButton} label="EM ANDAMENTO" />
              <MenuItem selectedButton={selectedButton} selectButton={setSelectedButton} label="HOMOLOGADO" />
              <MenuItem selectedButton={selectedButton} selectButton={setSelectedButton} label="FINALIZADO" />
              <MenuItem selectedButton={selectedButton} selectButton={setSelectedButton} label="SUSPENSO" />
              <MenuItem selectedButton={selectedButton} selectButton={setSelectedButton} label="CANCELADO" />
              {emailSuport && (
                <Flex
                  direction={'column'}
                  color="black"
                  bg="#6fbfd3"
                  align="center"
                  display={['none', 'none', 'none', 'flex', 'flex']}
                  textAlign="left"
                  w="100%"
                >
                  <Box p={1}>
                    <BiSupport size={35} />
                  </Box>

                  <Stack textAlign="left" w="100%" p={3} borderRadius="3px">
                    <TextS fontWeight="bold">Suporte ao candidato </TextS>
                    <TextXXS>
                      Em caso de dúvidas, necessidade de atendimento ou sugestão, entrar em contato exclusivamente
                      através do e-mail <strong>{emailSuport}</strong>
                    </TextXXS>
                  </Stack>
                </Flex>
              )}
            </Stack>
          </Stack>
        </Box>

        <Stack
          bg="white"
          w={['95%', '95%', '95%', '70%', '70%']}
          position={['inherit', 'inherit', 'inherit', 'absolute', 'absolute']}
          right={['0', '0', '0', '8%', '8%']}
          h="calc(100% - 110px)"
          mt={['2.5%', '2.5%', '2.5%', '25px', '25px']}
          mx={['2.5%', '2.5%', '2.5%', 'auto', 'auto']}
          borderTop="6px solid "
          borderTopColor="secondaryColor"
          boxShadow="2px 2px 10px rgba(0,0,0,0.7)"
        >
          <Flex flexDir="column" gap={3}>
            <TextXL
              px="30px"
              py={['30px', '30px', '30px', '30px', '30px', '40px']}
              borderBottom="1px solid darkGrey "
              boxShadow={'0px 2px 5px 0px rgba(0,0,0,0.3)'}
            >
              PROCESSOS SELETIVOS - <strong>{selectedButton || 'TODOS'}</strong>
            </TextXL>
          </Flex>
          {(data?.results?.length === 0 && (
            <Stack justify="center" alignItems="center" fontSize="25px" h="calc(100% - 126px)">
              <TextM fontWeight="semibold">Sem dados para esta busca</TextM>
            </Stack>
          )) || (
            <Stack overflow="auto" overflowX="hidden" maxH="100%" p={10} gap={10}>
              {isLoading ? (
                <Stack w="100%" h="100%" align="center" justify="center">
                  <Spinner />
                </Stack>
              ) : (
                data?.results?.map((selectiveProcess, index) => (
                  <Stack key={selectiveProcess?.id}>
                    <SelectiveProcess data={selectiveProcess} />
                    {index !== data?.results?.length - 1 && <Divider border={'1px solid darkGrey'} />}
                  </Stack>
                ))
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
};

export default Homepage;
