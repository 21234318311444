import { Badge, Divider, Flex, Stack, Td, Text, Tr, useMediaQuery } from '@chakra-ui/react';
import HeaderTable from '../../../../components/pages/CandidateArea/TableTest';
import TableCustom from '../../../../components/pages/CandidateArea/Table';
import { useNavigate } from 'react-router-dom';
import { useRequestMyImpugnation } from '../../../../services/hooks/CandidateArea/useRequestMyImpugnation';
import { TextXS } from '../../../../components/Text/Index';
import { badgeColor } from '../../../../utils/badgeFunction';

const Impugnation: React.FC = () => {
  const headerImpugnation = ['PROTOCOLO', 'PROCESSO SELETIVO', 'SITUAÇÃO', 'AÇÕES'];
  const { data } = useRequestMyImpugnation();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 1024px)');
  return (
    <Stack
      mt={10}
      px={['10px', '10px', '10px', '50px', '50px']}
      pb={'10px'}
      flexDir="column"
      justifyContent="space-between"
    >
      {data && (
        <>
          <HeaderTable mainTitle="LISTAGEM DE SOLICITAÇÕES DE IMPUGNAÇÃO DE EDITAL" fontWeight="bold" />
          {isMobile ? (
            <>
              {data.map((impugnation) => (
                <Stack border="1px solid" borderTopLeftRadius={4} borderTopRightRadius={4} w={'100%'} bg="primaryColor">
                  <Text
                    color="white"
                    fontWeight="bold"
                    fontSize={13}
                    p={2}
                    px={4}
                    borderTopLeftRadius={3}
                    borderTopRightRadius={3}
                  >
                    {impugnation?.selective_process_name}
                  </Text>
                  <Stack bg="white" key={impugnation?.selective_process_id} p={5}>
                    <Flex gap={1} justify="center" flexDir={'column'}>
                      <Text fontWeight="bold">
                        Protocolo:{' '}
                        <Text fontWeight="normal" as={'span'}>
                          {impugnation?.protocol}
                        </Text>
                      </Text>
                    </Flex>

                    <Flex justify="center" flexDir={'column'} gap={1}>
                      <Text fontWeight="bold">
                        Situação:{' '}
                        <Badge
                          borderRadius={4}
                          textAlign="center"
                          lineHeight="normal"
                          p={1}
                          px={2}
                          bg={badgeColor(impugnation?.status)?.bgColor || 'black'}
                          color={badgeColor(impugnation?.status)?.color || 'white'}
                        >
                          {impugnation?.status}
                        </Badge>
                      </Text>
                    </Flex>

                    <Flex gap={1}>
                      <Text
                        onClick={() => {
                          navigate('/painel/impugnacao/detalhes', { state: impugnation?.id });
                        }}
                        cursor="pointer"
                        color="colorLink"
                        textAlign="center"
                        fontWeight="bold"
                      >
                        Detalhes
                      </Text>
                    </Flex>
                    <Divider />
                  </Stack>
                </Stack>
              ))}
            </>
          ) : (
            <TableCustom headers={headerImpugnation}>
              {data &&
                data?.map((impugnation) => (
                  <Tr key={impugnation.id} textAlign="center">
                    <Td textAlign="center">
                      <TextXS> {impugnation?.protocol || 'Sem dados'}</TextXS>
                    </Td>
                    <Td textAlign="center">
                      <TextXS>{impugnation?.selective_process_name || 'Sem dados'}</TextXS>
                    </Td>
                    <Td textAlign="center">
                      <Badge
                        borderRadius={4}
                        textAlign="center"
                        lineHeight="normal"
                        p={1}
                        px={2}
                        bg={badgeColor(impugnation?.status)?.bgColor || 'black'}
                        color={badgeColor(impugnation?.status)?.color || 'white'}
                      >
                        {impugnation?.status}
                      </Badge>
                    </Td>
                    <Td
                      onClick={() => {
                        navigate('/painel/impugnacao/detalhes', { state: impugnation?.id });
                      }}
                      cursor="pointer"
                      color="colorLink"
                      textAlign="center"
                    >
                      <TextXS>Detalhes</TextXS>
                    </Td>
                  </Tr>
                ))}
            </TableCustom>
          )}
        </>
      )}
    </Stack>
  );
};

export default Impugnation;
