import { Divider, Flex, Spinner, Stack } from '@chakra-ui/react';
import MainCard from '../../../../components/MainCard/Index';
import { useGetRegistration } from '../../../../services/hooks/Registration/useGetRegistration';
import { useLocation } from 'react-router-dom';
import { TextM, TextXL, TextXS, TextXXS } from '../../../../components/Text/Index';
import { usePreliminaryResult } from '../../../../services/hooks/CandidateArea/useGetPreliminaryResult';
import ClassificationBox from '../../../../components/ClassificationComponent/ClassificationBox';

const PreliminaryResult: React.FC = () => {
  const registration_id = useLocation().state;
  const getRegistration = useGetRegistration(registration_id || '');
  const getPreliminaryResult = usePreliminaryResult(registration_id);

  const isLoading = getPreliminaryResult.isLoading || getRegistration.isLoading;

  const verifyResult = () => {
    if (
      getPreliminaryResult.data?.classifications?.extensive_competition?.classification ||
      getPreliminaryResult.data?.classifications.black_or_brown?.classification ||
      getPreliminaryResult.data?.classifications?.pcd?.classification ||
      getPreliminaryResult.data?.classifications?.general?.classification
    ) {
      return true;
    }
    return false;
  };
  return (
    <MainCard title="PROCESSO SELETIVO - " subtitle="RESULTADO">
      {(isLoading && (
        <Stack>
          <Spinner />
        </Stack>
      )) || (
        <Stack mx={[5, 5, 5, 20, 20, 20]}>
          <Flex
            justify={['flex-start', 'flex-start', 'flex-start', 'space-between', 'space-between']}
            flexDir={['column-reverse', 'column-reverse', 'column-reverse', 'row', 'row']}
          >
            <Stack marginTop={['60px', '60px', '60px', 0, 0]}>
              <Flex gap={2} flexDir={['column', 'column', 'row', 'row', 'row']}>
                <TextXS>
                  <strong>Nome completo: </strong>
                  {getRegistration.data?.candidate?.user?.full_name || '--'}
                </TextXS>
                {getRegistration.data?.candidate?.social_name && (
                  <TextXS>
                    <strong>Nome social: </strong>
                    {getRegistration.data?.candidate?.social_name || '--'}
                  </TextXS>
                )}
                <TextXS>
                  <strong>CPF: </strong>
                  {getRegistration.data?.candidate?.cpf || '--'}
                </TextXS>
              </Flex>

              <TextXS>
                <strong>Número de Inscrição:</strong> {getRegistration.data?.registration_code || '--'}
              </TextXS>
              <TextXS>
                <strong>Processo seletivo: </strong>
                {getRegistration.data?.selective_process_vacancy?.selective_process?.name || '--'}
              </TextXS>
              <TextXS>
                <strong>
                  {getRegistration.data?.selective_process_vacancy?.cultural_house
                    ? getRegistration.data?.selective_process_vacancy.class_level
                      ? 'Turma: '
                      : 'Turma - Primeira opção: '
                    : 'Vaga: '}
                </strong>
                {getRegistration.data?.selective_process_vacancy?.name || '--'}
                <TextXS>{getRegistration.data?.selective_process_vacancy?.details || ''}</TextXS>
              </TextXS>
              {getRegistration.data?.second_vacancy?.name && (
                <TextXS>
                  <strong>Turma - Segunda opção: </strong>
                  {getRegistration.data?.second_vacancy?.name || '--'}
                  <TextXS>{getRegistration.data?.second_vacancy?.details || ''}</TextXS>
                </TextXS>
              )}
            </Stack>
          </Flex>

          <Stack>
            <Stack p={0} m={0}>
              <TextM textAlign="center" fontWeight="bold" textTransform="uppercase">
                Nota das provas
              </TextM>

              <Flex
                alignItems="center"
                flexWrap="wrap"
                justify={['center', 'center', 'center', 'center', 'center', 'center']}
                gap={3}
              >
                {getPreliminaryResult.data?.notes?.map((note) => (
                  <Stack
                    border="1px solid"
                    minWidth="250px"
                    maxW={['45%', '45%', '45%', 'fit-content']}
                    borderColor="primaryColor"
                    alignItems="center"
                    justifyContent="center"
                    px={2}
                    bg="linear-gradient(135deg, #002E5B, #004080)"
                    color="white"
                    borderRadius={4}
                    gap={0}
                    py={2}
                  >
                    <TextXL textAlign="center" fontWeight="bold">
                      {note.value || '--'}
                      <TextXXS ml="2px">Pontos</TextXXS>
                    </TextXL>
                    <Divider borderColor="white" />
                    <TextXXS px={2} py={1}>
                      {note.name.toUpperCase() || '--'}
                    </TextXXS>
                  </Stack>
                ))}
              </Flex>
            </Stack>

            <Divider pt={3} borderColor="secondaryColor" />

            {verifyResult() && (
              <Stack p={0} m={0}>
                <TextM textAlign="center" fontWeight="bold" textTransform="uppercase">
                  RESULTADO
                </TextM>

                <Flex
                  alignItems="center"
                  flexWrap="wrap"
                  justify={['center', 'center', 'center', 'center', 'center', 'center']}
                  gap={3}
                  py={2}
                >
                  {getPreliminaryResult.data?.classifications?.extensive_competition?.classification && (
                    <ClassificationBox
                      title="Ampla concorrência"
                      classification={
                        getPreliminaryResult?.data?.classifications?.extensive_competition?.classification || ''
                      }
                      status={getPreliminaryResult?.data?.classifications?.extensive_competition?.status || ''}
                    />
                  )}

                  {getPreliminaryResult.data?.classifications.black_or_brown?.classification && (
                    <ClassificationBox
                      title="Pretos e pardos"
                      classification={getPreliminaryResult?.data?.classifications?.black_or_brown?.classification || ''}
                      status={getPreliminaryResult?.data?.classifications?.black_or_brown.status || ''}
                    />
                  )}

                  {getPreliminaryResult.data?.classifications?.pcd?.classification && (
                    <ClassificationBox
                      title="Pessoa com deficiência"
                      classification={getPreliminaryResult?.data?.classifications?.pcd?.classification || ''}
                      status={getPreliminaryResult?.data?.classifications?.pcd?.status || ''}
                    />
                  )}

                  {getPreliminaryResult.data?.classifications?.general?.classification && (
                    <ClassificationBox
                      title="Geral"
                      classification={getPreliminaryResult?.data?.classifications?.general?.classification || ''}
                      status={getPreliminaryResult?.data?.classifications?.general?.status || ''}
                    />
                  )}
                </Flex>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </MainCard>
  );
};

export default PreliminaryResult;
