import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { IAttachmentsFile } from '../SpecialCondition/useGetSpecialCondition';

export interface IDataToRegistration {
  id: string;
  name: string;
  registration_period: string;
  solective_process_type_name: string;
  firearms_act: string;
  juror_under_article_440: string;
  allows_special_condition: boolean;
  special_condition: ISpecialCondition[];
  vacancies: IVacancyprops;
  is_cultural_house: boolean;
  cultural_houses?: ICulturalHouse[];
  allow_second_registration: boolean;
  display_terms_acceptance_before_registration: boolean;
  exemption_request_notices: string;
  registration_confirmation: string;
  display_text_use_term: string;
}

export interface ICulturalHouse {
  id: 'aa18cbaf-9696-4396-8432-99c2760c3814';
  name: 'Cultura Francesa';
  types: ISelectionItem[];
}

export interface IVacancyprops {
  normal: IVacancy[];
  is_level_test: IVacancy[];
  is_initial_semester: IVacancy[];
}

export interface IVacancy {
  id: string;
  name: string;
  has_pcd: boolean;
  category: string;
  has_black_or_brown: boolean;
  type_of_competition: ISelectionItem[];
  selective_process_id: string;
}

export interface ISelectionItem {
  id: string;
  name: string;
}

export interface ISpecialCondition {
  id: string;
  name: string;
  attachments: IAttachmentsFile[];
  has_attachment: boolean;
  is_social_name?: boolean;
}

const RequestGetDataToRegistration = async (selectiveProcessId: string) => {
  const response = await configApi.get<IDataToRegistration>(
    `candidate-area/data-to-registration/${selectiveProcessId}/`,
  );
  return response.data;
};

export const useRequestGetDataToRegistration = (selectiveProcessId: string) => {
  return useQuery({
    queryKey: ['getDataToRegistration', selectiveProcessId],
    queryFn: () => RequestGetDataToRegistration(selectiveProcessId),
    refetchOnWindowFocus: false,
  });
};
