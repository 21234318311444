import React, { memo } from 'react';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import JSBarcode from 'jsbarcode';
import logoInstitute from '../../assets/logoInstitute.png';
import paid from '../../assets/paid.png';
import depreciation from '../../assets/depreciation.png';
import { EInvoiceStatus, IInvoiceData } from './types';

interface ICell {
  label: string;
  value: string;
  width: number;
  noMargin?: boolean;
  align?: 'right' | 'left' | 'center' | 'justify';
  borderTop?: boolean;
}

const Cell: React.FC<ICell> = ({ label, value, width, noMargin, align, borderTop }: ICell) => {
  const styles = StyleSheet.create({
    cellBox: {
      flexDirection: 'column',
      paddingHorizontal: 3,
      borderRight: `${noMargin ? 0 : 1}px solid black`,
      borderTop: `${borderTop ? 1 : 0}px solid black`,
      width: width,
    },
    label: {
      fontSize: 6.3,
      marginBottom: 4,
    },
    value: {
      fontSize: 8.5,
      marginBottom: 1,
      textAlign: align ? align : 'left',
    },
  });
  return (
    <View style={styles.cellBox}>
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};

export interface IPayloadTicket {
  our_number: string;
  status: string;
  digitable_line: string;
  barcode: string;
  ticket_number: string;
  ticket_value: string;
  ticket_due_date: string;
  beneficiary: {
    name: string;
    cnpj: string;
    address_street: string;
    address_number: string;
    address_zip_code: string;
    address_state: string;
    address_neighborhood: string;
    address_city: string;
    agency: string;
    code: string;
    wallet: string;
  };
  payer: {
    name: string;
    cpf_cnpj: string;
    address_street: string;
    address_number: string;
    address_zip_code: string;
    address_state: string;
    address_neighborhood: string;
    address_city: string;
  };
  detailTicket: {
    discounts_rebates: string;
    other_deductions: string;
    traffic_ticket: string;
    additions: string;
    amount_charged: string;
    demonstrative: string[];
    instructions: string[];
    payment_place: string;
    number_document: string;
    date_document: string;
    type_document: string;
    accept: string;
    processing_date: string;
    bank_Usage: string;
    kind: string;
  };
}

interface IOwnProps {
  payloadTicket: IInvoiceData;
}

const GenerateTicket: React.FC<IOwnProps> = ({ payloadTicket }: IOwnProps) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      padding: '40px 50px',
    },
    header: {
      paddingLeft: 3,
      borderBottom: '1px dashed black',
      marginHorizontal: 3,
    },
    title: {
      textAlign: 'center',
      fontSize: 8,
      fontWeight: 'bold',
      marginBottom: 15,
    },
    instuctionForPrint: {
      color: 'black',
      fontSize: 6,
      margin: 1,
    },
    boxBoletoInfo: {
      flexDirection: 'row',
      marginTop: 10,
    },
    infoBoleto: {
      color: 'black',
      fontSize: 8,
      fontWeight: 'normal',
      margin: 1,
    },
    receiptOfPayment: {
      fontSize: 7,
      marginBottom: 2,
      paddingTop: 10,
      textAlign: 'right',
    },
    body: {
      flexGrow: 1,
      marginTop: 7,
    },
    logo: {
      width: 130,
    },
    digitableLineBox: {
      marginTop: 10,
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    miniLogo: {
      width: 82,
      marginBottom: 1,
      marginRight: 2,
    },
    code: {
      borderLeft: '1px solid black',
      borderRight: '1px solid black',
      fontSize: 13,
      paddingHorizontal: 5,
      paddingBottom: 2,
      paddingTop: 1,
    },
    firstTable: {
      border: '1px solid black',
    },
    lineTable: {
      flexDirection: 'row',
      borderTop: '1px solid black',
    },
    footerFirstTable: {
      justifyContent: 'space-between',
      fontSize: 7,
      flexDirection: 'row',
      marginHorizontal: 5,
    },
    demonstrativeBox: {
      fontSize: 10,
      marginHorizontal: 5,
      marginTop: 7,
      borderBottom: '1px dashed black',
      paddingBottom: 1,
    },
    instruction: {
      borderTop: '1px solid black',
      flexDirection: 'row',
      paddingLeft: 5,
    },
    boxInstructions: {
      flex: 1,
      borderRight: '1px solid black',
    },
    label: {
      fontSize: 6.3,
      marginBottom: 4,
    },
    value: {
      fontSize: 8.5,
      marginBottom: 3,
    },
    boxWriteOffCode: {
      alignItems: 'flex-end',
      alignContent: 'center',
    },
    boxWriteOffCodeText: {
      width: 153,
      borderLeft: '1px solid black',
      fontSize: 6.3,
      paddingLeft: 5,
      paddingVertical: 3,
    },
    barCode: {
      height: 50,
      marginTop: 2,
      marginLeft: -4,
      width: 405,
    },
    stamp: {
      position: 'absolute',
      width: '395px',
      height: '300px',
      left: '20%',
      top: '33%',
      opacity: 0.5,
    },
  });

  const canvas = document.createElement('canvas');
  let barcode = '';
  if (payloadTicket?.barcode) {
    JSBarcode(canvas, payloadTicket?.barcode || '', {
      displayValue: false,
      format: 'ITF',
    });
    barcode = canvas.toDataURL();
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Instruções de Impressão</Text>
          <Text style={styles.instuctionForPrint}>
            - Imprima em impressora jato de tinta (ink jet) ou laser em qualidade normal ou alta (Não use modo
            econômico)
          </Text>
          <Text style={styles.instuctionForPrint}>
            - Utilize folha A4 (210 x 297 mm) ou Carta (216 x 279 mm) e margens mínimas à esquerda e à direita do
            formulário
          </Text>
          <Text style={styles.instuctionForPrint}>
            - Corte na linha indicada. Não rasure, risque, fure ou dobre a região onde se encontra o código de barras.
          </Text>
          <Text style={styles.instuctionForPrint}>
            - Caso não apareça o código de barras no final, clique em F5 para atualizar esta tela
          </Text>
          <Text style={styles.instuctionForPrint}>
            - Caso tenha problemas ao imprimir, copie a seqüencia numérica abaixo e pague no caixa eletrônico ou no
            internet banking:
          </Text>
          <View>
            <View style={styles.boxBoletoInfo}>
              <View>
                <Text style={styles.infoBoleto}>Linha Digitável:</Text>
                <Text style={styles.infoBoleto}>Número:</Text>
                <Text style={styles.infoBoleto}>Valor:</Text>
              </View>
              <View style={{ marginLeft: 14 }}>
                <Text style={styles.infoBoleto}>{payloadTicket?.digitable_line}</Text>
                <Text style={styles.infoBoleto}>{payloadTicket?.ticket_number}</Text>
                <Text style={styles.infoBoleto}>{payloadTicket?.ticket_value.toFixed(2)}</Text>
              </View>
            </View>
            <Text style={styles.receiptOfPayment}>Recibo de Pagamento</Text>
          </View>
        </View>
        <View style={styles.body}>
          <View style={{ flexDirection: 'row' }}>
            <Image src={logoInstitute} style={styles.logo} />
            <View
              style={{
                marginLeft: 35,
                marginTop: 1.5,
              }}
            >
              <Text style={styles.instuctionForPrint}>{payloadTicket?.beneficiary?.name}</Text>
              <Text style={styles.instuctionForPrint}>{payloadTicket?.beneficiary?.cnpj}</Text>
              <Text style={styles.instuctionForPrint}>{payloadTicket?.beneficiary?.address_street}</Text>
              <Text style={styles.instuctionForPrint}>
                {payloadTicket?.beneficiary?.address_zip_code} - {payloadTicket?.beneficiary?.address_city} -{' '}
                {payloadTicket?.beneficiary?.address_state}
              </Text>
            </View>
          </View>
          <View style={styles.digitableLineBox}>
            <Image src={logoInstitute} style={styles.miniLogo} />
            <Text style={styles.code}>001-9</Text>
            <Text
              style={{
                textAlign: 'right',
                width: '74%',
                fontSize: 10.5,
                paddingRight: 5,
                paddingBottom: 2,
              }}
            >
              {payloadTicket?.digitable_line}
            </Text>
          </View>
          <View style={styles.firstTable}>
            <View style={{ ...styles.lineTable, border: 'none' }}>
              <Cell label="Beneficiário" value={payloadTicket?.beneficiary?.name} width={210} />
              <Cell
                label="Agência/Código do beneficiário"
                value={`${payloadTicket?.beneficiary?.agency} / ${payloadTicket?.beneficiary?.code}`}
                width={105}
              />
              <Cell label="Espécie" value={payloadTicket?.detailTicket?.kind} width={30} />
              <Cell label="Quantidade" value="" width={45} />
              <Cell label="Nosso Número" value={payloadTicket?.our_number} width={110} align="right" noMargin />
            </View>
            <View style={styles.lineTable}>
              <Cell label="Número do Documento" value={payloadTicket?.detailTicket?.number_document} width={145} />
              <Cell label="CPF/CNPJ" value={payloadTicket?.beneficiary?.cnpj} width={107} />
              <Cell label="Vencimento" value={payloadTicket?.ticket_due_date} width={88} />
              <Cell
                label="Valor do Documento"
                value={`R$ ${payloadTicket?.ticket_value?.toFixed(2)}`}
                width={153}
                align="right"
                noMargin
              />
            </View>
            <View style={{ ...styles.lineTable }}>
              <Cell label="(-) Descontos/Abatimentos" value=" " width={89} />
              <Cell label="(-) Outras Deduções" value=" " width={88} />
              <Cell label="(+) Mora Multa" value=" " width={75} />
              <Cell label="(+) Acréscimos" value=" " width={88} />
              <Cell label="(=) Valor Cobrado" value=" " width={153} align="right" noMargin />
            </View>
            <View style={styles.lineTable}>
              <Cell
                label="Pagador"
                value={`${payloadTicket?.payer.name} / CPF: ${payloadTicket?.payer?.cpf_cnpj}`}
                width={500}
                noMargin
              />
            </View>
          </View>
          <View style={styles.footerFirstTable}>
            <Text>Demonstrativo</Text>
            <Text>Autenticação mecânica</Text>
          </View>
          <View style={styles.demonstrativeBox}>
            <Text
              style={{
                ...styles.receiptOfPayment,
                marginTop: 20,
              }}
            >
              Corte na linha pontilhada
            </Text>
          </View>
          <View style={styles.digitableLineBox}>
            <Image src={logoInstitute} style={styles.miniLogo} />
            <Text style={styles.code}>001-9</Text>
            <Text
              style={{
                textAlign: 'right',
                width: '74%',
                fontSize: 10.5,
                paddingRight: 5,
                paddingBottom: 2,
              }}
            >
              {payloadTicket?.digitable_line}
            </Text>
          </View>
          <View style={styles.firstTable}>
            <View style={{ ...styles.lineTable, border: 'none' }}>
              <Cell label="Local de pagamento" value={payloadTicket?.detailTicket?.payment_place} width={340} />
              <Cell label="Vencimento" value={payloadTicket?.ticket_due_date} width={153} align="right" noMargin />
            </View>
            <View style={styles.lineTable}>
              <Cell
                label="Beneficiário"
                value={`${payloadTicket?.beneficiary?.name} / CNPJ: ${payloadTicket?.beneficiary?.cnpj}`}
                width={340}
              />
              <Cell
                label="Agência/Código beneficiário"
                value={`${payloadTicket?.beneficiary?.agency} / ${payloadTicket?.beneficiary?.code}`}
                width={153}
                noMargin
                align="right"
              />
            </View>
            <View style={{ ...styles.lineTable }}>
              <Cell label="Data do documento" value={payloadTicket?.detailTicket?.date_document} width={85} />
              <Cell label="Número do documento" value={payloadTicket?.detailTicket?.number_document} width={110} />
              <Cell label="Espécie Doc." value="REC" width={45} />
              <Cell label="Aceite" value={payloadTicket?.detailTicket?.accept} width={30} />
              <Cell label="Data processamento" value={payloadTicket?.detailTicket?.processing_date} width={70} />
              <Cell label=" Nosso número" value={payloadTicket?.our_number} width={153} align="right" noMargin />
            </View>
            <View style={{ ...styles.lineTable }}>
              <Cell label="Uso do Banco" value=" " width={92} />
              <Cell label="Carteira" value={payloadTicket?.beneficiary?.wallet} width={80} />
              <Cell label="Espécie" value={payloadTicket?.detailTicket?.kind} width={35} />
              <Cell label="Quantidade" value=" " width={85} />
              <Cell label="Valor Documento" value={`R$ ${payloadTicket?.ticket_value.toFixed(2)}`} width={76} />
              <Cell
                label="Valor Documento"
                value={`R$ ${payloadTicket?.ticket_value.toFixed(2)}`}
                width={166}
                align="right"
                noMargin
              />
            </View>
            <View style={styles.instruction}>
              <View style={styles.boxInstructions}>
                <Text style={styles.label}>
                  Instruções de responsabilidade do beneficiário. Qualquer dúvida sobre este boleto, contate o
                  beneficiário{' '}
                </Text>
                {payloadTicket?.detailTicket?.instructions.map((instruction, index) => (
                  <Text style={styles.value} key={index}>
                    {instruction}
                  </Text>
                ))}
              </View>
              <View>
                <Cell label="(-) Desconto / Abatimentos)" value=" " width={153} align="right" noMargin />
                <Cell label="(-) Outras deduções" value=" " width={153} align="right" borderTop noMargin />
                <Cell label="(+) Mora / Multa" value=" " width={153} borderTop align="right" noMargin />
                <Cell label="(+) Outros acréscimos" value=" " width={153} align="right" borderTop noMargin />
                <Cell label="(=) Valor cobrado" value=" " width={153} align="right" borderTop noMargin />
              </View>
            </View>
            <View style={styles.instruction}>
              <View
                style={{
                  ...styles.boxInstructions,
                  borderRight: 'none',
                }}
              >
                <Text style={styles.label}>Pagador</Text>
                <Text style={styles.value}>
                  {`${payloadTicket?.payer?.name} / CPF:
                                    ${payloadTicket?.payer?.cpf_cnpj}`}
                </Text>
                <Text style={styles.value}>
                  {`${payloadTicket?.payer?.address_street} - ${payloadTicket?.payer?.address_number} - ${payloadTicket?.payer?.address_neighborhood}`}
                </Text>
                <Text style={styles.value}>
                  {`${payloadTicket?.payer?.address_zip_code} - ${payloadTicket?.payer?.address_state} - ${payloadTicket?.payer?.address_city}`}
                </Text>
                <View style={styles.boxWriteOffCode}>
                  <Text style={styles.boxWriteOffCodeText}>Cód. Baixa</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.footerFirstTable}>
            <Text>Sacador/Avalista</Text>
            <Text>Autenticação mecânica - Ficha de Compensação</Text>
          </View>
        </View>
        {barcode && <Image src={barcode} style={styles.barCode} />}
        {payloadTicket?.status?.toUpperCase() === EInvoiceStatus.paid && <Image src={paid} style={styles.stamp} />}
        {(payloadTicket?.status?.toUpperCase() === EInvoiceStatus.depreciation ||
          payloadTicket?.status?.toUpperCase() === EInvoiceStatus.canceled) && (
          <Image src={depreciation} style={styles.stamp} />
        )}
      </Page>
    </Document>
  );
};

export default memo(GenerateTicket);
