import { Flex, Stack } from '@chakra-ui/react';
import { useContext } from 'react';
import { ContextAuth } from '../../contexts/Authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextXS } from '../Text/Index';

export enum ENavbar {
  inicio = '/painel',
  recursos = '/painel/recursos',
  Isenções = '/painel/isencoes',
  meusDados = '/painel/meus-dados',
  voltarParaOSite = '/',
  processoSeletivo = '/processo-seletivo',
}

const Navbar: React.FC = () => {
  const { isAuth } = useContext(ContextAuth);
  const navigate = useNavigate();
  const path = useLocation().pathname;

  const validateInitialPath =
    path.includes(ENavbar.inicio) &&
    path !== '/painel/isencoes' &&
    !path.includes('recursos') &&
    !path.includes('meus-dados');

  if (isAuth) {
    return (
      <Flex alignItems={'center'} justifyContent="center" flex={1} display={['none', 'none', 'none', 'flex', 'flex']}>
        <Flex position="relative" align="center" h="100%">
          <TextXS
            fontWeight="bold"
            color={path.includes(ENavbar.processoSeletivo) || path === '/' ? 'black' : 'grey'}
            px={5}
            align="center"
            py={2}
            borderRight="1px solid lightGray"
            textTransform="uppercase"
            onClick={() => {
              navigate('/');
            }}
            cursor="pointer"
            _after={
              ((path.includes(ENavbar.processoSeletivo) || path === '/') && {
                content: `''`,
                width: 'calc(100% - 1px)',
                position: 'absolute',
                height: '4px',
                left: 0,
                bottom: 0,
                background: 'secondaryColor',
                borderRadius: '2px',
              }) ||
              {}
            }
          >
            Processos seletivos
          </TextXS>
        </Flex>

        <Flex position="relative" align="center" h="100%">
          <TextXS
            fontWeight="bold"
            color={validateInitialPath ? 'black' : 'grey'}
            px={5}
            align="center"
            py={2}
            borderRight="1px solid lightGray"
            textTransform="uppercase"
            onClick={() => {
              navigate('/painel');
            }}
            cursor="pointer"
            _after={
              (validateInitialPath && {
                content: `''`,
                width: 'calc(100% - 1px)',
                position: 'absolute',
                height: '4px',
                left: 0,
                bottom: 0,
                background: 'secondaryColor',
                borderRadius: '2px',
              }) ||
              {}
            }
          >
            MINHAS inscrições
          </TextXS>
        </Flex>

        {/* <Flex position="relative" align="center" h="100%">
          <TextXS
            fontWeight="bold"
            color={validateInitialPath ? 'black' : 'grey'}
            px={5}
            align="center"
            py={2}
            borderRight="1px solid lightGray"
            textTransform="uppercase"
            onClick={() => {
              navigate('/');
            }}
            cursor="pointer"
            _after={
              ((path.includes(ENavbar.processoSeletivo) || path === '/') && {
                content: `''`,
                width: 'calc(100% - 1px)',
                position: 'absolute',
                height: '4px',
                left: 0,
                bottom: 0,
                background: 'secondaryColor',
                borderRadius: '2px',
              }) ||
              {}
            }
          >
            Processos seletivos
          </TextXS>
        </Flex> */}

        <Flex position="relative" align="center" h="100%">
          <TextXS
            fontWeight="bold"
            color={path === ENavbar.Isenções ? 'black' : 'grey'}
            px={5}
            py={2.5}
            borderRight="1px solid lightGray"
            textTransform="uppercase"
            textAlign="center"
            onClick={() => {
              navigate('/painel/isencoes');
            }}
            cursor="pointer"
            _after={
              (path === ENavbar.Isenções && {
                content: `''`,
                width: 'calc(100% - 1px)',
                position: 'absolute',
                height: '4px',
                left: 0,
                bottom: 0,
                background: 'secondaryColor',
                borderRadius: '2px',
              }) ||
              {}
            }
          >
            Isenções
          </TextXS>
        </Flex>

        <Flex position="relative" align="center" h="100%">
          <TextXS
            fontWeight="bold"
            color={path.includes(ENavbar.recursos) ? 'black' : 'grey'}
            px={5}
            align="center"
            py={2}
            borderRight="1px solid lightGray"
            textAlign="center"
            textTransform="uppercase"
            onClick={() => {
              navigate('/painel/recursos');
            }}
            cursor="pointer"
            _after={
              (path.includes(ENavbar.recursos) && {
                content: `''`,
                width: 'calc(100% - 1px)',
                position: 'absolute',
                height: '4px',
                left: 0,
                bottom: 0,
                background: 'secondaryColor',
                borderRadius: '2px',
              }) ||
              {}
            }
          >
            Recursos
          </TextXS>
        </Flex>

        <Flex position="relative" align="center" h="100%">
          <TextXS
            fontWeight="bold"
            color={path === ENavbar.meusDados ? 'black' : 'grey'}
            onClick={() => {
              navigate('/painel/meus-dados');
            }}
            px={5}
            py={2.5}
            textTransform="uppercase"
            textAlign="center"
            cursor="pointer"
            _after={
              (path === ENavbar.meusDados && {
                content: `''`,
                width: 'calc(100% - 1px)',
                position: 'absolute',
                height: '4px',
                left: 0,
                bottom: 0,
                background: 'secondaryColor',
                borderRadius: '2px',
              }) ||
              {}
            }
          >
            Meus dados
          </TextXS>
        </Flex>
      </Flex>
    );
  }

  return <Stack h="100%" gap={0} justifyContent="center" display={['none', 'none', 'none', 'flex', 'flex']}></Stack>;
};

export default Navbar;
