import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoicePDF from '../invoice';
import { memo, useRef, useState } from 'react';
import { useInvoiceGenerator } from '../../../services/hooks/Invoice/useInvoiceGenerator';
import { FaBarcode } from 'react-icons/fa';
// import { FaPix } from 'react-icons/fa6';
import { QRCodeCanvas } from 'qrcode.react';
import { useQueryClient } from '@tanstack/react-query';
import { TextM } from '../../Text/Index';
import { BeatLoader } from 'react-spinners';
import { AxiosError } from 'axios';
interface IOwnProps {
  registrationId: string;
}

enum ETypePayment {
  INVOICE = 0,
  PIX = 1,
}

const InvoicePDFButton: React.FC<IOwnProps> = ({ registrationId }) => {
  const [typePayment, setTypePayment] = useState<ETypePayment>();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data, isLoading, refetch } = useInvoiceGenerator(registrationId);
  const query = useQueryClient();
  const toast = useToast();
  const textRef = useRef<HTMLTextAreaElement>(null);

  const handleCopy = () => {
    textRef.current?.select();
    document.execCommand('copy');
    window.getSelection()?.removeAllRanges();
    textRef.current?.blur();
  };

  if (isLoading) {
    return (
      <Stack height="100%" align="center" flex={1} py={10}>
        <BeatLoader color="#0064de" />
        <TextM textAlign="center" color="colorLink">
          Por favor aguarde, estamos gerando seu boleto.
        </TextM>
      </Stack>
    );
  }

  return (
    <>
      <Modal {...{ onOpen, onClose, isOpen }}>
        <ModalOverlay />
        <ModalContent
          marginStart={3}
          marginEnd={3}
          gap={0}
          maxW={['100%', '300px', '600px', '600px', '600px']}
          padding="5px"
          minW="300px"
        >
          <ModalHeader>
            <Text fontWeight="bold" fontSize={['16px', '16px', '16px', '16px', '16px']}>
              PAGAMENTO VIA PIX
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody scrollBehavior="initial" p={[0, 0, 4, 4, 4]}>
            <Stack align="center">
              <QRCodeCanvas size={150} value={data?.qrCode?.emv} />
              <Textarea
                _selection={{ backgroundColor: 'none' }}
                ref={textRef}
                readOnly
                mt={2}
                value={data?.qrCode?.emv}
                h={['250px', '250px', '250px', '120px', '120px']}
                w="100%"
              />
              <Button color="white" bg="primaryColor" mt={2} onClick={handleCopy}>
                Cópiar código
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack w="fit-content" mt={3}>
        {(data && (
          <Stack>
            <RadioGroup>
              <Flex gap={7}>
                <Text as="label" onClick={() => setTypePayment(ETypePayment.INVOICE)} cursor="pointer" htmlFor="boleto">
                  <Stack align="center" gap={1}>
                    <FaBarcode size={55} />
                    <Radio id="boleto" value="boleto" name="payment-method">
                      Boleto
                    </Radio>
                  </Stack>
                </Text>
                {/* <Text as="label" onClick={() => setTypePayment(ETypePayment.PIX)} cursor="pointer" htmlFor="pix">
                  <Stack align="center" gap={1}>
                    <FaPix color="#32BCAD" size={55} />
                    <Radio id="pix" value="pix" name="payment-method">
                      Pix
                    </Radio>
                  </Stack>
                </Text> */}
              </Flex>
            </RadioGroup>
            {(typePayment === ETypePayment.INVOICE && (
              <PDFDownloadLink document={<InvoicePDF payloadTicket={data} />} fileName="boleto.pdf">
                <Button
                  p={2}
                  fontSize="14px"
                  color="white"
                  bg="primaryColor"
                  w="100%"
                  mt={3}
                  whiteSpace="break-spaces"
                  h="fit-content"
                >
                  Emitir boleto
                </Button>
              </PDFDownloadLink>
            )) ||
              (typePayment === ETypePayment.PIX && (
                <Button
                  p={2}
                  fontSize="14px"
                  color="white"
                  bg="primaryColor"
                  w="100%"
                  mt={3}
                  whiteSpace="break-spaces"
                  h="fit-content"
                  onClick={() => onOpen()}
                >
                  Emitir Pix
                </Button>
              ))}
          </Stack>
        )) || (
          <Button
            _hover={'none'}
            onClick={() => {
              refetch().then((response) => {
                if (response.isError) {
                  const responseError = response?.error as AxiosError<{ message: string }>;
                  toast({
                    title: 'Erro',
                    description:
                      responseError?.response?.data?.message || 'Erro na solicitação, tente novamente mais tarde',
                    status: 'error',
                    duration: 7000,
                    isClosable: true,
                  });
                } else {
                  query.invalidateQueries({ queryKey: ['registratio_data'] });
                  toast({
                    title: 'Sucesso',
                    description: 'Boleto emitido com sucesso',
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                  });
                }
              });
            }}
            isDisabled={isLoading}
            p={3}
            color="white"
            bg="green"
            w={['100%', '100%', 'fit-content', 'fit-content', 'fit-content']}
            whiteSpace="break-spaces"
            h="fit-content"
          >
            GERAR DADOS PARA PAGAMENTO
          </Button>
        )}
      </Stack>
    </>
  );
};

export default memo(InvoicePDFButton);
