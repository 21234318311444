import { Flex, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useGetClientData } from '../../services/hooks/ClientData/useGetClientData';

const IframeComponent: React.FC = () => {
  const ClientData = useGetClientData();
  return (
    <Flex w="100%" py={10} justify="center" bg="white" h="100%">
      <Stack backgroundColor="#ffffff" padding="30px 20px" fontSize={9} w="100%" height="100%" alignItems="center">
        <Image src={ClientData?.data?.client_logo} w="150px" />
        <Text id="title" mt="15px"></Text>
        <Stack flexDirection="column" alignItems="flex-end" width="100%" marginTop="15px" marginBottom="15px">
          <Flex>
            <Text fontWeight="bold">Nº da Inscrição:</Text>
            <Text id="registration-number" ml={1}></Text>
          </Flex>
          <Flex>
            <Text fontWeight="bold">Data:</Text>
            <Text id="registration-date" ml={1}></Text>
          </Flex>
        </Stack>
        <Text id="selective-process-name" textAlign="center">
          PROCESSO SELETIVO -
        </Text>
        <Flex>
          <Text id="spvLabel1" fontWeight="bold" textAlign="center"></Text>
          <Text id="selective-process-vacancy" fontWeight="normal"></Text>
        </Flex>
        <Flex>
          <Text id="spvLabel2" fontWeight="bold" textAlign="center"></Text>
          <Text id="selective-process-second-vacancy" fontWeight="normal"></Text>
        </Flex>
        <Stack width="100%" padding="5px" marginTop="25px">
          <Flex>
            <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              NOME:
            </Text>
            <Text id="name" ml={1}></Text>
          </Flex>

          <Flex>
            <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              CPF:
            </Text>
            <Text id="cpf" ml={1}></Text>
          </Flex>

          <Flex>
            <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              DOCUMENTO DE IDENTIFICAÇÃO:
            </Text>
            <Text id="idoc" ml={1}></Text>
          </Flex>
          <Flex>
            <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              SEXO:
            </Text>
            <Text id="gender" ml={1}></Text>
          </Flex>

          <Flex>
            <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              DATA DE NASCIMENTO:
            </Text>
            <Text id="dob" ml={1}></Text>
          </Flex>

          <Flex>
            <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              ENDEREÇO:
            </Text>
            <Text id="address" ml={1}></Text>
          </Flex>

          <Text fontWeight="bold" id="contact" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
            CONTATOS:
          </Text>

          <Stack id="contacts"></Stack>

          {/* <Flex>
            <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              ISENTO:
            </Text>
            <Text id="exemption" ml={1}></Text>
          </Flex> */}

          <Flex>
            <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              INSCRIÇÃO:
            </Text>
            <Text id="rs" ml={1}></Text>
          </Flex>
          <Flex id="payment_status">
            {/* <Text fontWeight="bold" paddingTop="0px" paddingBottom="0px" paddingLeft="5px">
              SITUAÇÃO DE PAGAMENTO:
            </Text>
            <Text id="payment_status" ml={1}></Text> */}
          </Flex>

          {/* <Stack paddingLeft="5px" id="type_of_competition"></Stack> */}
          <Stack paddingLeft="5px" id="pcd" flexDir="column"></Stack>
          <Stack paddingLeft="5px" id="special_condition"></Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default IframeComponent;
