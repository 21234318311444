import { Document, Image, Page, StyleSheet, Text, View, Link } from '@react-pdf/renderer';
import React, { memo } from 'react';
import { IGetRegistration } from '../../services/hooks/Registration/useGetRegistration';
import { useGetClientData } from '../../services/hooks/ClientData/useGetClientData';

interface IOwnProps {
  data?: IGetRegistration;
}

const ProofRegistration: React.FC<IOwnProps> = ({ data }: IOwnProps) => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#ffffff',
      padding: '30px 20px',
      fontSize: 9,
      marginHorizontal: '50px',
      height: '100%',
      alignItems: 'center',
    },
    logo: {
      width: 150,
    },
    title: {
      marginTop: 25,
    },
    boxInfo: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '100%',
      marginTop: 25,
      marginBottom: 25,
    },
    subTitle: {
      textAlign: 'center',
      marginBottom: 10,
    },
    candidateInformation: {
      width: '100%',
      padding: 5,
      marginTop: 25,
    },
    candidateValue: {
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 5,
    },
    signatureBox: {
      borderTop: '1px solid black',
      width: '38%',
      paddingTop: 7,
      alignItems: 'center',
    },
    footer: {
      width: '80%',
      flexDirection: 'row',
      marginTop: 30,
    },
    linkText: {
      fontSize: 8,
      color: 'blue',
      textDecoration: 'underline',
      paddingLeft: 15,
    },
    linkGroup: { marginTop: 3, fontSize: 8, color: 'blue', textDecoration: 'underline', paddingLeft: 15 },
    specialCondition: {
      marginTop: 15,
    },
    textSpecialCondition: {
      paddingBottom: 7,
      paddingLeft: 15,
      marginTop: 10,
    },
    linkTextSpecialCondition: {
      fontSize: 8,
      color: 'blue',
      textDecoration: 'underline',
      paddingLeft: 30,
    },
  });
  const ClientData = useGetClientData();

  const address = data?.candidate;
  const listAttachPCD =
    data?.reserved_vacancy.filter((list) => list.type.toLowerCase().includes('pcd'))[0] ?? undefined;
  return (
    <Document>
      <Page size="A4" style={{ justifyContent: 'space-around' }}>
        <View style={styles.page}>
          <Image source={ClientData?.data?.client_logo} style={styles.logo} />

          {data?.exemptions && data?.exemptions?.length > 0 && (
            <Text style={styles.title}>{'COMPROVANTE DE ISENÇÃO'}</Text>
          )}

          {data?.exemptions && data?.exemptions?.length === 0 && (
            <Text style={styles.title}>{'COMPROVANTE DE INSCRIÇÃO'}</Text>
          )}

          <View style={styles.boxInfo}>
            <Text>Número da Inscrição: {data?.registration_code}</Text>
            <Text>Data e hora da inscrição: {data?.created_at?.br || ''}</Text>
          </View>
          <Text style={styles.subTitle}>
            PROCESSO SELETIVO: {data?.selective_process_vacancy?.selective_process.name || '--'}
          </Text>

          {data?.selective_process_vacancy.cultural_house && (
            <>
              <Text style={styles.subTitle}>
                {data?.selective_process_vacancy.class_level ? 'Turma: ' : 'Turma - Primeira Opção:'}
                {data?.selective_process_vacancy?.name || '--'}
              </Text>
              {data?.second_vacancy?.name && (
                <Text style={styles.subTitle}>Turma - Segunda Opção: {data?.second_vacancy?.name || '--'}</Text>
              )}
            </>
          )}

          {!data?.selective_process_vacancy.cultural_house && (
            <Text style={styles.subTitle}>Vaga/Cargo: {data?.selective_process_vacancy?.name || '--'}</Text>
          )}

          <View style={styles.candidateInformation}>
            <Text style={styles.candidateValue}>
              NOME COMPLETO: <Text>{data?.candidate?.user.full_name || '--'}</Text>
            </Text>

            {data?.candidate?.social_name && (
              <Text style={styles.candidateValue}>
                NOME SOCIAL: <Text>{data?.candidate?.social_name || '--'}</Text>
              </Text>
            )}

            <Text style={styles.candidateValue}>
              CPF: <Text>{data?.candidate?.cpf || '--'}</Text>
            </Text>
            <Text style={styles.candidateValue}>
              DOCUMENTO DE IDENTIFICAÇÃO:{' '}
              <Text>{`${data?.candidate?.document_type?.name || ''}: ${data?.candidate?.document_number || ''} - ${data?.candidate?.document_issuing_body || ''} - ${data?.candidate?.document_issuing_body_uf.initials || ''} - expedido: ${data?.candidate?.document_date_of_issuance.br || ''}`}</Text>
            </Text>
            <Text style={styles.candidateValue}>
              SEXO: <Text>{data?.candidate?.gender?.name || '--'}</Text>
            </Text>
            <Text style={styles.candidateValue}>
              DATA DE NASCIMENTO: <Text>{data?.candidate?.date_of_birth.br || '--'}</Text>
            </Text>
            <Text style={styles.candidateValue}>
              ENDEREÇO:{' '}
              <Text>{`${address?.address_street || '--'}, N° ${address?.address_number || '--'}, ${address?.address_complement + ',' || ''} ${address?.address_neighborhood || '--'} - CEP: ${address?.address_zip_code || '--'} - ${address?.address_city?.name || '--'}/${address?.address_state?.initials || '--'}`}</Text>
            </Text>
            <Text style={styles.candidateValue}>CONTATOS: </Text>
            <Text style={{ marginLeft: 20, marginTop: 5 }}>
              {data?.candidate.phone && `- Telefone: ${data?.candidate.phone || '--'}`}
            </Text>
            <Text style={{ marginLeft: 20, marginTop: 5 }}>
              {data?.candidate.cellphone && `- Celular: ${data?.candidate.cellphone || '--'}`}
            </Text>
            <Text style={{ marginLeft: 20, marginTop: 5 }}>- E-MAIL: {data?.candidate?.user?.email || '--'}</Text>

            {/* <Text style={styles.candidateValue}>
              ISENTO: <Text>{data?.is_exemption ? 'Sim' : 'Não'}</Text>
            </Text> */}
            <Text style={styles.candidateValue}>
              SITUAÇÃO DA INSCRIÇÃO: <Text>{data?.registration_status?.name || '=='}</Text>
            </Text>
            {data?.invoice?.status_invoice && (
              <Text style={styles.candidateValue}>
                SITUAÇÃO DE PAGAMENTO: <Text>{data?.invoice?.status_invoice}</Text>
              </Text>
            )}
            {data?.type_of_competition.map((modality) => (
              <Text key={modality.id} style={styles.candidateValue}>
                {modality?.name || '--'}: Sim
              </Text>
            ))}

            {listAttachPCD && listAttachPCD?.attachments?.length > 0 && (
              <Text style={styles.candidateValue}>Anexo de documentos PCD:</Text>
            )}

            {listAttachPCD &&
              listAttachPCD?.attachments?.length > 0 &&
              listAttachPCD.attachments.map((attach, index) => (
                <Link src={attach.url.split('?')[0]} style={styles.linkGroup} key={index}>
                  {`- ${attach.name}`}
                </Link>
              ))}

            {/* {data?.attachments?.medical_report &&
              data?.attachments?.medical_report.map((document, ind) => (
                <Link src={document.url.split('?')[0]} style={styles.linkGroup} key={ind}>
                  {`- ${document.name}`}
                </Link>
              ))} */}
            {data?.special_conditions && data?.special_conditions?.length > 0 && (
              <Text style={styles.specialCondition}>Condições Especiais:</Text>
            )}
            {data?.special_conditions?.map((item) => (
              <React.Fragment key={item?.id}>
                <Text style={styles.textSpecialCondition}>{item?.name}</Text>
                {item?.attachments?.map((file) => (
                  <Text key={file.id} style={{ fontSize: 8, marginLeft: 30 }}>
                    <Link src={file.url.split('?')[0]} style={styles.linkText} key={Math.random()}>
                      {`- ${file.name}`}
                    </Link>
                    {` - ${file.created_at.br || ''}`} (enviado pelo{' '}
                    {file.sent_by_client ? 'candidato' : 'administrador'})
                  </Text>
                ))}
              </React.Fragment>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default memo(ProofRegistration);
