import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface INewResource {
  attachmentFile?: FileList;
  detail?: string;
  questionNumber?: string;
  resourcePeriodId?: string;
  nis?: string;
  exemption?: string;
  proof_subject?: string;
}

const createResource = async (
  data: INewResource,
  resourceType: string,
  vacancyId: string,
  resourcePeriodId: string,
  selective_process_id: string,
) => {
  const formData = new FormData();

  if (data.attachmentFile && data.attachmentFile.length > 0) {
    for (let index = 0; index < data.attachmentFile.length; index++) {
      formData.append('resource_files', data.attachmentFile[index]);
    }
  }

  if (selective_process_id) {
    formData.append('selective_process', selective_process_id);
  }

  if (data.detail) {
    formData.append('observation', data.detail);
  }

  if (data.exemption) {
    formData.append('exemption', data.exemption);
  }

  if (resourceType) {
    formData.append('resource_type', resourceType);
  }

  if (vacancyId) {
    formData.append('vacancy', vacancyId);
  }

  if (resourcePeriodId) {
    formData.append('resource_period', resourcePeriodId);
  }

  if (data.questionNumber) {
    formData.append('question', data.questionNumber);
  }

  if (data.proof_subject) {
    formData.append('proof_subject', data.proof_subject);
  }

  if (data.nis) {
    formData.append('nis', data.nis);
  }

  const response = await configApi.post(`resources/create/`, formData);
  return response;
};

export const useCreateResource = (
  resourceType: string = '',
  vacancy_id: string = '',
  resourcePeriodId: string = '',
  selective_process_id: string = '',
) => {
  return useMutation({
    mutationFn: (data: INewResource) =>
      createResource(data, resourceType, vacancy_id, resourcePeriodId, selective_process_id),
    mutationKey: ['createResource', resourceType, vacancy_id],
  });
};
