import { Badge, Box, Divider, Flex, Stack, Td, Text, Tr, useMediaQuery } from '@chakra-ui/react';
import HeaderTable from '../../../../components/pages/CandidateArea/TableTest';
import TableCustom from '../../../../components/pages/CandidateArea/Table';
import { useRequestMyRegistration } from '../../../../services/hooks/CandidateArea/useRequestMyRegistration';
import ActionList from './ActionList';
import { TextS, TextXS } from '../../../../components/Text/Index';
import { useNavigate } from 'react-router-dom';
import { badgeColor } from '../../../../utils/badgeFunction';
import MessageForUser from '../../../../components/pages/SelectiveProcess/MessageForUser';

const HistoryRegistration: React.FC = () => {
  const { data } = useRequestMyRegistration();
  const headerMyRegistrationCultureHouse = ['INSCRIÇÃO', 'VAGA/CARGO', 'DATA DE INSCRIÇÃO', 'SITUAÇÃO', 'AÇÕES'];
  const headerMyRegistration = ['INSCRIÇÃO', 'TURMA', 'DATA DE INSCRIÇÃO', 'SITUAÇÃO', 'AÇÕES'];
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 1024px)');

  if (!data) {
    return (
      <Stack pb={'20px'} px={['10px', '10px', '10px', '50px', '50px']} flexDir="column">
        <HeaderTable bgColor="primaryColor" mainTitle="LISTAGEM DE INSCRIÇÕES" fontWeight="bold" />
        <TextS>Você ainda não possui inscrição em um processo seletivo</TextS>
      </Stack>
    );
  }
  const selectiveProcessMessages = data?.filter((selectiveProcess) => selectiveProcess.candidate_dashboard);

  return (
    <Stack pb={'20px'} px={['10px', '10px', '10px', '50px', '50px']} flexDir="column">
      {selectiveProcessMessages.map((sp, index) => {
        if (sp?.candidate_dashboard) {
          return (
            <MessageForUser
              mb={index + 1 === selectiveProcessMessages.length ? 5 : 1}
              informationText={`${sp?.selective_process_name?.toUpperCase() || 'Não informado'} ${sp?.candidate_dashboard || ''}`}
            />
          );
        }
      })}
      <HeaderTable bgColor="primaryColor" mainTitle="LISTAGEM DE INSCRIÇÕES" fontWeight="bold" />

      {data &&
        data?.map((constest, index) => {
          return (
            <>
              {isMobile ? (
                <Stack border="1px solid" borderTopLeftRadius={4} borderTopRightRadius={4} w={'100%'} bg="primaryColor">
                  <Text
                    color="white"
                    fontWeight="bold"
                    fontSize={13}
                    p={2}
                    px={4}
                    borderTopLeftRadius={3}
                    borderTopRightRadius={3}
                  >
                    {constest.selective_process_name}
                  </Text>
                  {constest.registrations.map((registration) => (
                    <Stack bg="white" key={registration.registration_id} p={5}>
                      <Flex gap={1} justify="center" flexDir={'column'}>
                        <Text fontWeight="bold">
                          Inscrição:{' '}
                          <Text fontWeight="normal" as={'span'}>
                            {registration.registration_code}
                          </Text>
                        </Text>
                      </Flex>

                      <Flex justify="center" flexDir={'column'} gap={1}>
                        {constest?.is_cultural_house ? (
                          <Box whiteSpace="break-spaces" w="100%" textAlign="left">
                            <TextXS>
                              <strong>{!registration?.second_vacancy ? 'Turma: ' : 'Turma - Primeira opção:'} </strong>
                            </TextXS>
                            <TextXS>{registration.vacancy_name}</TextXS>

                            {registration?.second_vacancy && (
                              <>
                                <TextXS mt={2}>
                                  <strong>Turma - Segunda opção: </strong>
                                </TextXS>
                                <TextXS>{registration?.second_vacancy}</TextXS>
                              </>
                            )}
                          </Box>
                        ) : (
                          <Box whiteSpace="break-spaces" w="100%" textAlign="center">
                            <TextXS>{registration.vacancy_name}</TextXS>
                          </Box>
                        )}
                        {/* <Text fontWeight="bold">
                          Vaga/Cargo:{' '}
                          <Text fontWeight="normal" as={'span'} maxW="250px">
                            {registration.vacancy_name}
                          </Text>
                        </Text> */}
                      </Flex>

                      <Flex justify="center" flexDir={'column'} gap={1}>
                        <Text fontWeight="bold">
                          Data de inscrição:{' '}
                          <Text fontWeight="normal" as={'span'}>
                            {registration.registration_date}
                          </Text>
                        </Text>
                      </Flex>

                      <Flex gap={1}>
                        <Text fontWeight="bold">Situação:</Text>
                        <Flex align="center">
                          <Badge
                            borderRadius={4}
                            textAlign="center"
                            lineHeight="normal"
                            p={1}
                            px={2}
                            bg={badgeColor(registration.status)?.bgColor || 'black'}
                            color={badgeColor(registration.status)?.color || 'white'}
                          >
                            {registration?.status}
                          </Badge>
                        </Flex>
                      </Flex>
                      <Divider />
                      <ActionList
                        registrationId={registration.registration_id}
                        actions={registration.actions}
                        selectiveProcessId={constest.selective_process_id}
                      />
                    </Stack>
                  ))}
                </Stack>
              ) : (
                <TableCustom
                  key={index}
                  title={constest.selective_process_name}
                  headers={constest.is_cultural_house ? headerMyRegistration : headerMyRegistrationCultureHouse}
                >
                  {constest?.registrations?.map((registration) => (
                    <Tr key={registration.registration_id}>
                      <Td textAlign="center">
                        <TextXS
                          cursor="pointer"
                          color="colorLink"
                          onClick={() => navigate('/painel/inscricao/', { state: registration.registration_id })}
                        >
                          {registration.registration_code}
                        </TextXS>{' '}
                      </Td>
                      {constest?.is_cultural_house ? (
                        <Td whiteSpace="break-spaces" w="100%" textAlign="left">
                          <TextXS>
                            <strong>{!registration?.second_vacancy ? 'Turma: ' : 'Turma - Primeira opção:'} </strong>
                          </TextXS>
                          <TextXS>{registration.vacancy_name}</TextXS>

                          {registration?.second_vacancy && (
                            <>
                              <TextXS mt={2}>
                                <strong>Turma - Segunda opção: </strong>
                              </TextXS>
                              <TextXS>{registration?.second_vacancy}</TextXS>
                            </>
                          )}
                        </Td>
                      ) : (
                        <Td whiteSpace="break-spaces" w="100%" textAlign="center">
                          <TextXS>{registration.vacancy_name}</TextXS>
                        </Td>
                      )}

                      <Td textAlign="center">
                        {' '}
                        <TextXS>{registration.registration_date}</TextXS>
                      </Td>
                      <Td textAlign="center">
                        {' '}
                        <Badge
                          borderRadius={4}
                          textAlign="center"
                          lineHeight="normal"
                          p={1}
                          px={2}
                          bg={badgeColor(registration?.status)?.bgColor || 'black'}
                          color={badgeColor(registration?.status)?.color || 'white'}
                        >
                          {registration?.status}
                        </Badge>
                      </Td>
                      <Td>
                        <ActionList
                          registrationId={registration.registration_id}
                          actions={registration.actions}
                          selectiveProcessId={constest.selective_process_id}
                        />
                      </Td>
                    </Tr>
                  ))}
                </TableCustom>
              )}
            </>
          );
        })}
    </Stack>
  );
};

export default HistoryRegistration;
