import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  ListItem,
  Select,
  Spinner,
  Stack,
  Textarea,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { validateMaxFile, validateTypeFile, validationSizeFile } from '../../../utils/validationForm';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IResourceExemptions, useRequestNewResource } from '../../../services/hooks/Resources/useGetRequestNewResource';
import { useCreateResource } from '../../../services/hooks/Resources/useCreateResource';
import MainCard from '../../../components/MainCard/Index';
import MessageError from '../../../components/MessageError';
import { TextL, TextS, TextXS } from '../../../components/Text/Index';
import { DefaultButton } from '../../../components/ButtonDefault/Index';
import { IoLink } from 'react-icons/io5';
import { useGetProofSubjects } from '../../../services/hooks/Resources/useGetProofSubjects';

const NewResource: React.FC = () => {
  const [selectedName, setSelectedName] = useState('');

  const navigate = useNavigate();
  // const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const resource_admin_id = useLocation().state as string;
  const { data } = useRequestNewResource(resource_admin_id);
  const subjectData = useGetProofSubjects();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (event: any) => {
    const selectedId = event.target.value;
    const selectedItem = data?.exemptions?.find((item) => item?.id === selectedId);
    setSelectedName(selectedItem ? selectedItem.type : '');
  };
  const schema = Yup.object().shape({
    exemption: Yup.string().when({
      is: () => data?.exemptions,
      then: (schema) =>
        schema
          .test('Allow send exemptions', '', () => {
            if (data?.exemptions.length === 0) {
              toast({
                title: 'Atenção',
                description: 'Você atingiu o limite maximo de recursos',
                status: 'info',
                duration: 4000,
                isClosable: true,
              });
              return true;
            }
            return true;
          })
          .required('Campo obrigatorio'),
    }),
    attachmentFile: Yup.mixed()
      .test('invalidExtension', 'Verifique o nome do arquivo enviado', (file) => {
        const listFile = file as FileList;
        let hasError = true;

        for (let index = 0; index < listFile?.length; index++) {
          const hasDot = listFile[index].name.split('.');
          if (hasDot.length > 2) hasError = false;
        }

        return hasError;
      })
      .test('validationSizeFile', `Tamanho máximo para arquivos é de ${data?.size_attached}MB`, (file) => {
        const listFile = file as FileList;

        if (!data?.resource.has_attachment) return true;

        if (listFile?.length > 0) {
          const response = validationSizeFile(listFile, data?.size_attached);
          // if (!response) {
          //   setIsOpen(true);
          // }
          return response;
        }

        return true;
      })
      .test('validationMaxFile', `Quantidade máxima de arquivos é de ${data?.max_attached} arquivos`, (file) => {
        const listFile = file as FileList;
        if (!data?.resource.has_attachment) return true;

        if (listFile?.length > 0) {
          return validateMaxFile(listFile, data?.max_attached);
        }
        return true;
      })
      .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
        const listFile = file as FileList;
        if (!data?.resource.has_attachment) return true;
        if (listFile?.length > 0) {
          return validateTypeFile(listFile);
        }
        return true;
      }),

    detail: Yup.string().when({
      is: () => data?.resource?.has_observation,
      then: (schema) => schema.required('Campo obrigatório'),
    }),
    nis: Yup.string().when({
      is: () => verifyTypeExemption()?.type.toLowerCase().includes('cadúnico'),
      then: (schema) => schema.required('Campo obrigatório').length(11, 'Campo NIS deve conter 11 digitos'),
    }),
    questionNumber: Yup.string().min(1, 'Número da questão inválido').max(999, 'Número da questão inválido'),
    proof_subject: Yup.string().when({
      is: () => data?.resource?.has_question,
      then: (schema) => schema.required('Campo obrigatório'),
    }),
  });

  const { register, handleSubmit, formState, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    values: {
      exemption: data?.exemptions?.length === 1 ? data?.exemptions[0]?.id : '',
    },
  });

  const { mutateAsync, isPending } = useCreateResource(
    data?.resource?.id,
    data?.vacancy?.id,
    data?.resource_period_id,
    data?.selective_process_id,
  );

  const newResource = (data: FieldValues) => {
    mutateAsync(data)
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Seu recurso foi enviado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate(-1);
      })
      .catch((error) => {
        toast({
          title: 'Atenção',
          description: error?.response?.data?.message || 'Erro ao enviar o recurso, tente novamente',
          status: 'warning',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const clear = () => {
    reset({ attachmentFile: '' });
  };

  useEffect(() => {
    if (!resource_admin_id) navigate(-1);
  }, []);

  useEffect(() => {
    if (!selectedName.includes('CADÚNICO')) {
      setValue('nis', '');
    }
  }, [selectedName]);

  const verifyTypeExemption = (): IResourceExemptions | undefined => {
    let response = undefined;

    if (data?.exemptions) {
      data?.exemptions
        .filter((exemption) => watch('exemption') === exemption.id)
        .map((ex) => {
          response = ex;
        });
    }

    return response;
  };

  const ex = verifyTypeExemption();

  return (
    <MainCard height="100%" title="ÁREA DO CANDIDATO" subtitle="- NOVO RECURSO">
      <Stack as="form" onSubmit={handleSubmit(newResource)} px={['10px', '10px', '10px', '50px', '50px']} gap={10}>
        <Stack>
          <TextL fontWeight="bold">{data?.selective_process_name}</TextL>
          <TextXS>
            <strong>Recurso: </strong> {data?.period_identification}
          </TextXS>
          <TextXS>
            <strong>Período: </strong> {data?.resource_period}
          </TextXS>
          <TextXS>
            <strong>Inscrição:</strong>
            {data?.registration?.registration_code}
          </TextXS>
          <TextXS>
            <strong>Nome:</strong>
            {data?.registration?.id} {data?.registration?.candidate_name}
          </TextXS>
          {data?.vacancy?.name && (
            <TextXS>
              <strong>Vaga: </strong> {data?.vacancy?.name}
            </TextXS>
          )}

          <TextXS>
            <strong>Data de Inscrição: </strong> {data?.registration?.registration_date}
          </TextXS>
        </Stack>

        {data?.exemptions && data?.exemptions.length > 1 && (
          <>
            <Stack w={['100%', '100%', '100%', '50%', '50%']}>
              <FormControl display="flex" flexDir="row" alignItems="center">
                <TextXS fontWeight="bold" mr={2}>
                  Selecione o tipo de isenção:
                </TextXS>
                <Select {...register('exemption', { onChange: handleSelectChange })}>
                  <option value={''} hidden>
                    Selecione
                  </option>
                  {data?.exemptions?.length === 0 && <option value={''}>Sem pedidos de isenção encontrados</option>}
                  {data?.exemptions?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.type}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <MessageError>{formState.errors.exemption?.message}</MessageError>
            </Stack>
          </>
        )}

        {watch('exemption') && ex && (
          <Stack>
            <TextS fontWeight="bold">{ex?.type}</TextS>

            <TextXS>
              <strong>Resposta à solicitação de isenção:</strong>
              <TextXS as="span" dangerouslySetInnerHTML={{ __html: ex?.justification }} />
            </TextXS>

            {ex?.attachements && ex.attachements?.length > 0 && (
              <Stack>
                <TextXS fontWeight="bold">Anexos enviados ao solicitar isenção</TextXS>
                <UnorderedList styleType={"''"}>
                  {ex?.attachements?.map((attach) => (
                    <ListItem key={attach?.id}>
                      <Flex color="colorLink" align="center" gap={1}>
                        <IoLink />
                        <Link fontSize="14px" href={attach?.url} target="_blank">
                          {attach?.name}
                        </Link>
                        <TextXS color="black">
                          - {attach?.created_at} (Enviado pelo {attach?.sent_by_client ? 'Administrador)' : 'Cliente)'}
                        </TextXS>
                      </Flex>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Stack>
            )}
          </Stack>
        )}

        {data?.resource?.has_question && (
          <Stack w={['100%', '100%', '100%', '50%', '50%']} spacing={4}>
            <FormControl display="flex" flexDir="column" alignItems="flex-start">
              <TextXS fontWeight="bold" mb={2}>
                Questão:
              </TextXS>
              <Input {...register('questionNumber')} min={1} max={999} type="number" w="100%" />
              <MessageError>{formState.errors.questionNumber?.message}</MessageError>
            </FormControl>

            <FormControl display="flex" flexDir="column" alignItems="flex-start">
              <TextXS fontWeight="bold" mb={2}>
                Selecione a prova:
              </TextXS>
              <Select {...register('proof_subject', { onChange: handleSelectChange })} w="100%">
                <option value={''} hidden>
                  Selecione a prova
                </option>
                {subjectData?.data?.map((subject) => (
                  <option key={subject?.id} value={subject?.id}>
                    {subject?.name}
                  </option>
                ))}
              </Select>
              <MessageError>{formState?.errors?.proof_subject?.message}</MessageError>
            </FormControl>
          </Stack>
        )}

        {watch('exemption') && ex && ex.show_nis_field && ex.type?.toLowerCase()?.includes('cadúnico') && (
          <Stack w={['100%', '100%', '100%', '50%', '50%']}>
            <FormControl display="flex" flexDir="row" alignItems="center">
              <TextXS fontWeight="bold" mr={2}>
                NIS:{' '}
              </TextXS>
              <Input {...register('nis')} type="text" />
            </FormControl>
            <MessageError>{formState.errors.nis?.message}</MessageError>
          </Stack>
        )}

        {data?.resource?.has_observation && (
          <Stack w={['100%', '100%', '100%', '50%', '50%']}>
            <TextXS fontWeight="bold" mb={2}>
              Justificativa do recurso:
            </TextXS>
            <Textarea {...register('detail')} h="100px" />
            <MessageError>{formState.errors.detail?.message}</MessageError>
          </Stack>
        )}

        {data?.resource?.has_attachment && (
          <Stack>
            {/* <Button
              color="darkGrey"
              whiteSpace="break-spaces"
              w="fit-content"
              bg="white"
              border="1px solid darkGrey"
              boxShadow={'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;'}
              mt={4}
              onClick={() => setIsOpen((e) => !e)}
            >
              Enviar Arquivo
            </Button> */}

            <Stack w={['100%', '100%', '100%', '70%', '70%']}>
              <TextXS fontWeight="bold">Enviar anexos:</TextXS>
              <Stack mt={4} borderRadius="4px" h="180px" bg="lightGrey" justify="center" p={5}>
                <FormControl display="flex" alignItems="center">
                  <FormLabel>Arquivo</FormLabel>
                  <Input multiple {...register('attachmentFile')} bg="white" type="file" alignContent="center" />
                </FormControl>
                <MessageError ml="4rem">{formState.errors.attachmentFile?.message}</MessageError>
                <TextXS cursor="pointer" onClick={clear} color="colorLink" ml="4rem">
                  Limpar
                </TextXS>
              </Stack>
            </Stack>
          </Stack>
        )}

        <Stack mb={10} flexDir="row" alignItems="center" justify="flex-end">
          <DefaultButton
            bg="lightGrey"
            color="black"
            onClick={() => {
              clear();
              // setIsOpen((e) => !e);
              navigate(-1);
            }}
            label="Cancelar"
          />

          <DefaultButton isDisabled={isPending} type="submit" label={isPending ? <Spinner /> : 'Enviar'} />
        </Stack>
      </Stack>
    </MainCard>
  );
};
export default NewResource;
