import { Flex, Image, Stack, useDisclosure } from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import MenuMobile from './MenuMobile';
import ProfileWeb from './ProfileWeb';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';
import { useGetClientData } from '../../services/hooks/ClientData/useGetClientData';

const Header: React.FC = () => {
  const navigation = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ClientData = useGetClientData();

  return (
    <Stack zIndex={99} align="center" justify="space-between" bg="white" pr="15px" flexDir="row" boxShadow="none">
      <Image
        cursor="pointer"
        src={ClientData?.data?.main_logo}
        alt="logo"
        width="145px"
        mx="30px"
        py={3}
        onClick={() => navigation('/')}
      />

      <Flex w="100%" h="100%" justifyContent="space-between">
        <Navbar />

        <ProfileWeb />

        <Stack
          w="100%"
          display={['flex', 'flex', 'flex', 'none', 'none']}
          align="flex-end"
          justifyContent="center"
          cursor="pointer"
          onClick={onOpen}
        >
          <FaBars size={30} />
        </Stack>
      </Flex>

      <MenuMobile {...{ isOpen, onClose }} />
    </Stack>
  );
};

export default memo(Header);
